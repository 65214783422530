import { Checkbox, FormControl, FormControlLabel, FormLabel, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField } from "@mui/material";
import React, { FC, forwardRef, ReactNode, Ref, useEffect, useImperativeHandle, useState } from "react";
import { useDoorConfigurationBuilderActions } from "../../../../../../../store/reducers/door_configuration_builder";
import { useStoreDispatch, useStoreSelector } from "../../../../../../../hooks/StoreHooks";
import { Accessorio, Color, ColorSide, ColorType, ExternalDoorConfiguration, ExternalDoorOptions, Option } from "../../../../../../models/door_configuration";
import { useDialogs } from "../../../../../../providers/dialogs_provider";
import { useFormik } from "formik";
import { ColorCode, DoorType, FinishType, InstallationType, IntegratedDoorPosition, ModelType, NullableNumber, NullableString, OpeningDirection, OpeningMode, Optional } from "../../../../../../core/common_types";
import { useConfigurationsService } from "../../../../../../services/configurations_service";
import { getCurrentUser, numberCompare } from "../../../../../../core/common_functions";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useFinishes } from "../../../../../../providers/finish_provider";
import { getProductNameFromCode } from './../../../../../../../Utility';
import finishes from "../../../../../../assets/finishes.json";
import rals from "../../../../../../assets/rals.json";

type FormPortinaAffiancataProps = {
    options: ExternalDoorOptions
}

type FormPortinaAffiancataRef = {
    getData: () => Promise<ExternalDoorConfiguration>
}

export type FormPortinaAffiancataTypeRef = React.ElementRef<typeof FormPortinaAffiancata>;

export const FormPortinaAffiancata = forwardRef((props: FormPortinaAffiancataProps, ref: Ref<FormPortinaAffiancataRef>) => {
    const { base, doorConfigurationBuilder } = useStoreSelector(store => store);
    const finishesProvider = useFinishes();
    const dialogs = useDialogs();
    const dispatcher = useStoreDispatch();
    const {
        setPortinaAffiancata
    } = useDoorConfigurationBuilderActions();

    const formik = useFormik<ExternalDoorConfiguration>({
        initialValues: {
            portina: doorConfigurationBuilder.configuration?.portinaAffiancata?.portina ?? false,
            larghezza: doorConfigurationBuilder.configuration?.portinaAffiancata?.larghezza ?? 900,
            altezza: doorConfigurationBuilder.configuration?.portinaAffiancata?.altezza ?? 1900,
            usaStessaFinitura: doorConfigurationBuilder.configuration?.portinaAffiancata?.usaStessaFinitura ?? true,
            porta: doorConfigurationBuilder.configuration?.portinaAffiancata?.porta ?? undefined,
            modello: doorConfigurationBuilder.configuration?.portinaAffiancata?.modello ?? undefined,
            finitura: doorConfigurationBuilder.configuration?.portinaAffiancata?.finitura ?? undefined,
            tipoColoreEsterno: doorConfigurationBuilder.configuration?.portinaAffiancata?.tipoColoreEsterno ?? "ral_standard",
            coloreEsterno: doorConfigurationBuilder.configuration?.portinaAffiancata?.coloreEsterno ?? undefined,
            tipoColoreInterno: doorConfigurationBuilder.configuration?.portinaAffiancata?.tipoColoreInterno ?? "ral_standard",
            coloreInterno: doorConfigurationBuilder.configuration?.portinaAffiancata?.coloreInterno ?? ColorCode.bianco_traffico,
            externalFrameColorType: doorConfigurationBuilder.configuration?.portinaAffiancata?.externalFrameColorType ?? "ral_standard",
            externalFrameColorCode: doorConfigurationBuilder.configuration?.portinaAffiancata?.externalFrameColorCode ?? undefined,
            internalFrameColorType: doorConfigurationBuilder.configuration?.portinaAffiancata?.internalFrameColorType ?? "ral_standard",
            internalFrameColorCode: doorConfigurationBuilder.configuration?.portinaAffiancata?.internalFrameColorCode ?? undefined,
            externalHandleColorType: doorConfigurationBuilder.configuration?.portinaIntegrata?.externalHandleColorType ?? "ral_standard",
            externalHandleColorCode: doorConfigurationBuilder.configuration?.portinaIntegrata?.externalHandleColorCode ?? undefined,
            internalHandleColorType: doorConfigurationBuilder.configuration?.portinaIntegrata?.internalHandleColorType ?? "ral_standard",
            internalHandleColorCode: doorConfigurationBuilder.configuration?.portinaIntegrata?.internalHandleColorCode ?? undefined,
            finituraVetro: doorConfigurationBuilder.configuration?.portinaAffiancata?.finituraVetro ?? undefined,
            finituraLamiera: doorConfigurationBuilder.configuration?.portinaAffiancata?.finituraLamiera ?? undefined,
            usaColoreTelaioInternoComeEsterno: doorConfigurationBuilder.configuration?.portinaAffiancata?.usaColoreTelaioInternoComeEsterno ?? true,
            tipoInstallazione: doorConfigurationBuilder.configuration?.portinaAffiancata?.tipoInstallazione ?? InstallationType.in_luce,
            tipoApertura: doorConfigurationBuilder.configuration?.portinaAffiancata?.tipoApertura ?? "push",
            versoApertura: doorConfigurationBuilder.configuration?.portinaAffiancata?.versoApertura ?? "sx",
            accessori: doorConfigurationBuilder.configuration?.portinaAffiancata?.accessori ?? []
        },
        onSubmit: (model) => {

        }
    });

    const getOptions = (type: string, parentId?: NullableNumber) => {
        let ret = doorConfigurationBuilder.options
            .filter(o => o && o.type == type && (!parentId || o.parentId == parentId))
            .sort((a, b) => (!a || !b) ? 0 : a.name.localeCompare(b.name));

        return ret;
    }

    const renderSelectModello = () => {
        let modelli = getOptions("MODELLO", formik.values.porta?.id).filter(f => f?.name != FinishType.full_vision);

        if (modelli.length == 1 && !formik.values.modello) {
            formik.setFieldValue("modello", modelli[0]);
            return null;
        }

        if (modelli.length == 1 && formik.values.modello) return null;

        return (
            <FormControl fullWidth className="mt-3">
                <InputLabel id="modello">Modello</InputLabel>
                <Select
                    labelId="modello"
                    value={formik.values.modello?.id ?? ""}
                    label="Modello"
                    onChange={(e) => {
                        let option = doorConfigurationBuilder.options.find(o => o?.id == Number(e.target.value));
                        if (option) {
                            formik.setValues({
                                ...formik.values,
                                modello: option,
                                finitura: undefined,
                                tipoColoreEsterno: "ral_standard",
                                coloreEsterno: undefined,
                                finituraVetro: undefined,
                                finituraLamiera: undefined
                            });
                        }
                    }}>
                    {modelli.map(o => <MenuItem key={o?.id} value={o?.id}>{o?.name}</MenuItem>)}
                </Select>
            </FormControl>
        );
    }

    const renderSelectFinitura = () => {
        let finiture = getOptions("FINITURA", formik.values.modello?.id);

        if (isPlana()) {
            finiture.push({
                id: -1,
                name: "LAMIERA FORATA",
                optionId: -1,
                parentId: null,
                type: "FINITURA",
                value: ""
            });
        }

        if (finiture.length == 1 && !formik.values.finitura) {
            setDefaultColors(finiture[0]);
            return null;
        }

        if (finiture.length == 1 && formik.values.finitura) return null;

        return (
            <FormControl fullWidth className="mt-3">
                <InputLabel id="finitura">Finitura</InputLabel>
                <Select
                    labelId="finitura"
                    value={formik.values.finitura?.id ?? ""}
                    label="Finitura"
                    onChange={(e) => {
                        let option = finiture.find(o => o?.id == Number(e.target.value));
                        if (option) {
                            setDefaultColors(option);
                        }
                    }}>
                    {finiture.map(o => <MenuItem key={o?.id} value={o?.id}>{o?.name}</MenuItem>)}
                </Select>
            </FormControl>
        );
    }

    const setDefaultColors = (finish: Option) => {
        let colors = getColors("external", finish?.id);
        let externalColorCode = colors.length == 1 ? colors[0]?.code : null;

        let values: ExternalDoorConfiguration = {
            ...formik.values,
            finitura: finish,
            finituraVetro: undefined,
            finituraLamiera: undefined,
            tipoColoreEsterno: "ral_standard",
            coloreEsterno: externalColorCode,
            tipoColoreInterno: "ral_standard",
            coloreInterno: ColorCode.bianco_traffico,
        };

        if (finish?.name == FinishType.lamiera_forata) {
            values.tipoColoreEsterno = "ral_non_standard";
            values.tipoColoreInterno = "ral_non_standard";
        }

        formik.setValues(values);
    }

    const getColors = (side: ColorSide, finitura: NullableNumber) => {
        if (!finitura) return [];

        let ret = doorConfigurationBuilder.colors
            .filter(c => c && c.parentOptionId == finitura && c.side == side)
            .sort((a, b) => (!a || !b) ? 0 : numberCompare(a.sortOrder, b.sortOrder))

        return ret;
    }

    const canChangeExternalColorType = (): boolean => {
        if (!formik.values.finitura) return false;
        let finish = doorConfigurationBuilder.options.find(o => o?.id == formik.values.finitura?.id);
        if (!finish) return false;

        return finish.name != FinishType.glasspanel && finish.name != FinishType.special && finish.name != FinishType.material_hpl && finish.name != FinishType.wood_hpl && finish.name != FinishType.pietravera && finish.name != FinishType.woodstyle;
    }

    const canShowInternalColor = (): boolean => {
        if (!formik.values.finitura) return false;
        let finish = doorConfigurationBuilder.options.find(o => o?.id == formik.values.finitura?.id);
        if (!finish) return false;

        return (finish.name != FinishType.glasspanel && finish.name != FinishType.lamiera_forata && finish.name != FinishType.full_vision) || !formik.values.usaColoreTelaioInternoComeEsterno;
    }

    const isFullglass = (): boolean => {
        if (!formik.values.finitura) return false;
        let finish = doorConfigurationBuilder.options.find(o => o?.id == formik.values.finitura?.id);
        if (!finish) return false;

        return finish.name == FinishType.glasspanel;
    }

    // const isLamieraForata = (): boolean => {
    //     if (!formik.values.finitura) return false;
    //     let finish = doorConfigurationBuilder.options.find(o => o?.id == formik.values.finitura?.id);
    //     if (!finish) return false;

    //     return finish.name == FinishType.lamiera_forata;
    // }

    const isPlana = (): boolean => {
        if (!formik.values.porta) return false;
        let door = doorConfigurationBuilder.options.find(o => o?.id == formik.values.porta?.id);
        if (!door) return false;

        return door.name == DoorType.plana;
    }

    const isFullVision = (): boolean => {
        if (!formik.values.finitura) return false;
        let finish = doorConfigurationBuilder.options.find(o => o?.id == formik.values.finitura?.id);
        if (!finish) return false;

        return finish.name == FinishType.full_vision;
    }

    const renderLabelVerniciaturaNonStandard = (accVerniciatura: Accessorio): ReactNode => {
        let selected = formik.values.accessori.find(vns => vns.sku == accVerniciatura?.sku);
        return <span>{accVerniciatura?.name} - <span className="badge bg-light text-dark">&euro; {accVerniciatura?.price}</span></span>
    }

    const renderSelectVetratura = () => {
        let glasses: Option[] = [
            {id: -1, name: "Vetro camera 4/12/4 mm trasparente", value: "vetro_camera_temperato_4_12_4_trasparente", optionId: -1, parentId: null, type: "VETRATURA"},
            {id: -1, name: "Vetro camera 4/12/4 mm satinato", value: "vetro_camera_temperato_4_12_4_satinato_bianco", optionId: -1, parentId: null, type: "VETRATURA"},
            {id: -1, name: "Vetro laminato 8/9 mm trasparente", value: "vetro_laminato_8_9_trasparente", optionId: -1, parentId: null, type: "VETRATURA"},
            {id: -1, name: "Vetro laminato 8/9 mm satinato", value: "vetro_laminato_8_9_satinato_bianco", optionId: -1, parentId: null, type: "VETRATURA"}
        ];

        return (
            <FormControl fullWidth className="mt-3">
                <InputLabel id="glass_finish">Finitura vetro</InputLabel>
                <Select
                    labelId="glass_finish"
                    value={formik.values.finituraVetro?.value ?? ""}
                    label="Finitura vetro"
                    onChange={(e) => {
                        let option = glasses.find(o => o?.value == e.target.value);
                        if (option) {
                            formik.setValues({
                                ...formik.values,
                                finituraVetro: option
                            });
                        }
                    }}>
                    {glasses.map((o,idx) => <MenuItem key={"glass_"+idx} value={o?.value ?? undefined}>{o?.name}</MenuItem>)}
                </Select>
            </FormControl>
        );
    }

    const renderSelectLamieraForata = () => {
        let lamiere: Option[] = [
            {id: -1, name: "Lamiera con fori tondi", value: "lamiera_fori_tondi", optionId: -1, parentId: null, type: "LAMIERA_FORATA"},
            {id: -1, name: "Lamiera con fori quadrati", value: "lamiera_fori_quadrati", optionId: -1, parentId: null, type: "LAMIERA_FORATA"},
        ];

        debugger

        return (
            <FormControl fullWidth className="mt-3">
                <InputLabel id="lamiera_forata">Finitura lamiera</InputLabel>
                <Select
                    labelId="lamiera_forata"
                    value={formik.values.finituraLamiera?.value ?? ""}
                    label="Finitura lamiera"
                    onChange={(e) => {
                        let option = lamiere.find(o => o?.value == e.target.value);
                        if (option) {
                            formik.setValues({
                                ...formik.values,
                                finituraLamiera: option
                            });
                        }
                    }}>
                    {lamiere.map((o,idx) => <MenuItem key={"lam_"+idx} value={o?.value ?? undefined}>{o?.name}</MenuItem>)}
                </Select>
            </FormControl>
        );
    }

    const renderFormFinitura = () => {
        return (
            <>
                <FormControl fullWidth className="mt-3">
                    <InputLabel id="porta">Gamma</InputLabel>
                    <Select
                        labelId="porta"
                        value={formik.values.porta?.id ?? ""}
                        label="Gamma"
                        onChange={(e) => {
                            let option = doorConfigurationBuilder.options.find(o => o?.id == Number(e.target.value));
                            if (option) {
                                let values: ExternalDoorConfiguration = {
                                    ...formik.values,
                                    porta: option,
                                    modello: undefined,
                                    finitura: undefined,
                                    tipoColoreEsterno: "ral_standard",
                                    coloreEsterno: undefined,
                                    tipoColoreInterno: "ral_standard",
                                    coloreInterno: ColorCode.bianco_traffico,
                                    finituraVetro: undefined,
                                    finituraLamiera: undefined
                                };
                                formik.setValues(values);
                                dispatcher(setPortinaAffiancata(values));
                            }
                        }}>
                        {getOptions("PORTA").filter(o => o?.name != DoorType.full_vision).map(o => <MenuItem key={o?.id} value={o?.id}>{o?.name}</MenuItem>)}
                    </Select>
                </FormControl>

                {formik.values.porta && renderSelectModello()}

                {formik.values.modello && renderSelectFinitura()}

                {formik.values.finitura && canChangeExternalColorType() && <FormControl fullWidth className="mt-3">
                    <InputLabel id="tipo_colore_esterno">{isFullglass() || isFullVision() ? "Colore telaio" : "Colore esterno"}</InputLabel>
                    <Select
                        labelId="tipo_colore_esterno"
                        value={formik.values.tipoColoreEsterno ?? ""}
                        label={isFullglass() || isFullVision() ? "Colore telaio" : "Colore esterno"}
                        onChange={(e) => {
                            let values: ExternalDoorConfiguration = {
                                ...formik.values,
                                tipoColoreEsterno: e.target.value as ColorType,
                                coloreEsterno: null,
                            };
                            formik.setValues(values);
                            dispatcher(setPortinaAffiancata(values));
                        }}>
                        <MenuItem value={"ral_standard"}>RAL Standard</MenuItem>
                        <MenuItem value={"ral_non_standard"}>RAL NON Standard</MenuItem>
                        <MenuItem value={"a_campione"}>A Campione</MenuItem>
                    </Select>
                </FormControl>}

                {formik.values.finitura && formik.values.tipoColoreEsterno == "ral_standard" && !isFullglass() /*&& formik.values.finitura.value != FinishType.lamiera_forata*/ && <TextField
                    fullWidth
                    focused={false}
                    className={canChangeExternalColorType() ? "mt-1" : "mt-3"}
                    label={!formik.values.coloreEsterno ? "Seleziona il colore" : canChangeExternalColorType() ? "" : "Colore esterno"}
                    name={"coloreEsterno"}
                    value={getRalName(formik.values.coloreEsterno)}
                    InputProps={{
                        readOnly: true,
                        startAdornment: formik.values.coloreEsterno && (
                            <InputAdornment position="start">
                                {renderFinishPreview(formik.values.coloreEsterno)}
                            </InputAdornment>
                        ),

                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => dialogs?.selectColor({
                                    colors: getColors("external", formik.values.finitura?.id), selectedColor: undefined, onConfirm: selectedColor => {
                                        let values: ExternalDoorConfiguration = {
                                            ...formik.values,
                                            coloreEsterno: doorConfigurationBuilder.colors.find(c => c?.id == selectedColor)?.code
                                        };
                                        formik.setValues(values);
                                        dispatcher(setPortinaAffiancata(values));
                                    }
                                })}>
                                    <OpenInNewIcon style={{ color: "black" }} />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />}

                {formik.values.finitura && formik.values.tipoColoreEsterno == "ral_non_standard" && <TextField
                    fullWidth
                    focused={false}
                    className="mt-1"
                    label={!formik.values.coloreEsterno ? "Seleziona il colore" : ""}
                    name={"coloreEsterno"}
                    value={finishesProvider?.getRalName(formik.values.coloreEsterno)}
                    InputProps={{
                        readOnly: true,
                        startAdornment: formik.values.coloreEsterno && (
                            <InputAdornment position="start">
                                {finishesProvider?.getRalPreview(formik.values.coloreEsterno)}
                            </InputAdornment>
                        ),

                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => dialogs?.selectRalColor({
                                    onConfirm: selectedColor => {
                                        let values: ExternalDoorConfiguration = {
                                            ...formik.values,
                                            coloreEsterno: selectedColor
                                        };
                                        formik.setValues(values);
                                        dispatcher(setPortinaAffiancata(values));
                                    }
                                })}>
                                    <OpenInNewIcon style={{ color: "black" }} />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />}

                {/*formik.values.finitura && canShowInternalColor() && <FormControl fullWidth className="mt-3">
                    <InputLabel id="tipo_colore_interno">{isFullglass() || isFullVision() ? "Colore telaio interno" : "Colore interno"}</InputLabel>
                    <Select
                        labelId="tipo_colore_interno"
                        value={formik.values.tipoColoreInterno ?? ""}
                        label={isFullglass() || isFullVision() ? "Colore telaio interno" : "Colore interno"}
                        onChange={(e) => {
                            let values: ExternalDoorConfiguration = {
                                ...formik.values,
                                tipoColoreInterno: e.target.value as ColorType,
                                coloreInterno: null
                            };
                            formik.setValues(values);
                            dispatcher(setPortinaAffiancata(values));
                        }}>
                        <MenuItem value={"ral_standard"}>RAL Standard</MenuItem>
                        <MenuItem value={"ral_non_standard"}>RAL NON Standard</MenuItem>
                        <MenuItem value={"a_campione"}>A Campione</MenuItem>
                    </Select>
                </FormControl>*/}

                {formik.values.finitura && canShowInternalColor() && formik.values.tipoColoreInterno == "ral_standard" && <TextField
                    fullWidth
                    focused={false}
                    className="mt-3"
                    label={!formik.values.coloreInterno ? "Seleziona colore" : "Colore interno"}
                    name={"coloreInterno"}
                    value={finishesProvider?.getRalName(formik.values.coloreInterno)}
                    InputProps={{
                        readOnly: true,
                        startAdornment: formik.values.coloreInterno && (
                            <InputAdornment position="start">
                                {renderFinishPreview(formik.values.coloreInterno)}
                            </InputAdornment>
                        ),
                    }}
                />}

                {/*formik.values.finitura && canShowInternalColor() && formik.values.tipoColoreInterno == "ral_non_standard" && <TextField
                    fullWidth
                    focused={false}
                    className="mt-1"
                    label={!formik.values.coloreInterno ? "Colore interno" : ""}
                    name={"coloreInterno"}
                    value={finishesProvider?.getRalName(formik.values.coloreInterno)}
                    InputProps={{
                        readOnly: true,
                        startAdornment: formik.values.coloreInterno && (
                            <InputAdornment position="start">
                                {finishesProvider?.getRalPreview(formik.values.coloreInterno)}
                            </InputAdornment>
                        ),

                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => dialogs?.selectRalColor({
                                    onConfirm: selectedColor => {
                                        let values: ExternalDoorConfiguration = {
                                            ...formik.values,
                                            coloreInterno: selectedColor
                                        };
                                        formik.setValues(values);
                                        dispatcher(setPortinaAffiancata(values));
                                    }
                                })}>
                                    <OpenInNewIcon style={{ color: "black" }} />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />*/}

                {formik.values.finitura && (isFullglass() || isFullVision()) && renderSelectVetratura()}
                {formik.values.finitura && (formik.values.finitura.name == FinishType.lamiera_forata) && renderSelectLamieraForata()}
            </>
        );
    }

    const renderFormPortina = () => {
        return (
            <>
                <Grid container spacing={2} className="mt-2">
                    <Grid item lg={6}>
                        <TextField
                            error={formik.errors.larghezza != null}
                            variant="outlined"
                            label="Larghezza"
                            type={"number"}
                            className="w-full"
                            value={formik.values.larghezza}
                            InputProps={{
                                endAdornment: <InputAdornment position="start">mm</InputAdornment>,
                            }}
                            onChange={e => formik.setFieldValue("larghezza", Number(e.target.value))}
                            helperText={formik.errors.larghezza ?? null}
                        />
                    </Grid>
                    <Grid item lg={6}>
                        <TextField
                            error={formik.errors.altezza != null}
                            variant="outlined"
                            label="Altezza"
                            type={"number"}
                            className="w-full"
                            value={formik.values.altezza}
                            InputProps={{
                                endAdornment: <InputAdornment position="start">mm</InputAdornment>,
                            }}
                            onChange={e => formik.setFieldValue("altezza", Number(e.target.value))}
                            helperText={formik.errors.altezza ?? null}
                        />
                    </Grid>
                </Grid>

                <FormControl fullWidth className="mt-3">
                    <InputLabel id="tipoInstallazione">Tipo di installazione</InputLabel>
                    <Select
                        labelId="tipoInstallazione"
                        value={formik.values.tipoInstallazione}
                        label="Tipo di installazione"
                        onChange={(e) => {
                            formik.setFieldValue("tipoInstallazione", e.target.value);
                        }}>
                        <MenuItem value={InstallationType.in_luce}>In Luce</MenuItem>
                        <MenuItem value={InstallationType.oltre_luce}>Oltre Luce</MenuItem>
                    </Select>
                </FormControl>

                <FormControl fullWidth className="mt-3">
                    <InputLabel id="tipoApertura">Tipo di apertura</InputLabel>
                    <Select
                        labelId="tipoApertura"
                        value={formik.values.tipoApertura}
                        label="Tipo di apertura"
                        onChange={(e) => {
                            formik.setFieldValue("tipoApertura", e.target.value);
                        }}>
                        <MenuItem value={"push"}>A spingere</MenuItem>
                        {formik.values.tipoInstallazione == InstallationType.in_luce && <MenuItem value={"pull"}>A tirare</MenuItem>}
                    </Select>
                </FormControl>

                <FormControl fullWidth className="mt-3">
                    <InputLabel id="versoApertura">Verso di apertura</InputLabel>
                    <Select
                        labelId="versoApertura"
                        value={formik.values.versoApertura}
                        label="Verso di apertura"
                        onChange={(e) => {
                            formik.setFieldValue("versoApertura", e.target.value as OpeningDirection);
                            //dispatcher(setPorta(option.optionId));
                        }}>
                        <MenuItem value={"sx"}>Sinistro</MenuItem>
                        <MenuItem value={"dx"}>Destro</MenuItem>
                    </Select>
                </FormControl>

                <h6 className="text-primary mt-4">Modello e finitura</h6>
                <FormControlLabel
                    className="w-full"
                    control={
                        <Checkbox
                            checked={formik.values.usaStessaFinitura}
                            onChange={(e) => {
                                formik.setValues({
                                    ...formik.values,
                                    usaStessaFinitura: e.target.checked,
                                    porta: undefined,
                                    modello: undefined,
                                    finitura: undefined,
                                    tipoColoreEsterno: "ral_standard",
                                    coloreEsterno: undefined,
                                    tipoColoreInterno: "ral_standard",
                                    coloreInterno: e.target.checked ? ColorCode.bianco_traffico : undefined,
                                    finituraVetro: undefined,
                                    finituraLamiera: undefined
                                });
                            }}
                            sx={{ marginTop: "0", marginBottom: "0" }}
                        />
                    }
                    label={"Modello e finitura come porta " + getProductNameFromCode(doorConfigurationBuilder.configuration?.categoryCode)}
                    sx={{ marginTop: "0", marginBottom: "0" }}
                />

                {!formik.values.usaStessaFinitura && renderFormFinitura()}

                {formik.values.usaStessaFinitura && (doorConfigurationBuilder.isFinituraGlasspanel() || doorConfigurationBuilder.isGammaFullVision()) && renderSelectVetratura()}

                {renderSelectExternalFrameColorType()}
                {renderInputExternalFrameNsRal()}

                {renderSelectInternalFrameColorType()}
                {renderInputInternalFrameNsRal()}

                {renderSelectExternalHandleColorType()}
                {formik.values.externalHandleColorType == "ral_non_standard" && renderInputExternalHandleNsRal()}

                {renderSelectInternalHandleColorType()}
                {formik.values.internalHandleColorType == "ral_non_standard" && renderInputInternalHandleNsRal()}

                <h6 className="text-primary mt-4">Accessori</h6>
                {props.options.accessoriVari.map(a => <FormControlLabel
                    key={a?.id}
                    className="w-full mt-2"
                    control={
                        <Checkbox
                            checked={formik.values.accessori.filter(fm => fm.sku == a?.sku).length > 0}
                            onChange={(e) => {
                                let accessori = formik.values.accessori.map(a => ({ ...a }));
                                if (e.target.checked) {
                                    if (a && !accessori.find(fm => fm.sku == a?.sku)) {
                                        accessori.push({ sku: a.sku, notes: "" });
                                    }
                                } else {
                                    accessori = accessori.filter(fm => fm.sku != a?.sku);
                                }
                                let values: ExternalDoorConfiguration = {
                                    ...formik.values,
                                    accessori
                                };
                                formik.setValues(values);
                            }}
                            sx={{ marginTop: "0", marginBottom: "0" }}
                        />
                    }
                    label={
                        <span>{a?.name} - <span className="badge bg-light text-dark">&euro; {a?.price}</span></span>
                    }
                    sx={{ marginTop: "0", marginBottom: "0" }}
                />)}
            </>
        );
    }

    const renderSelectExternalColorType = () => {
        if (!doorConfigurationBuilder.configuration) return null;

        return (
            <FormControl fullWidth className={formik.values.tipoColoreEsterno == "ral_standard" ? "mb-3" : "mb-1"}>
                <InputLabel id="colore_esterno">Colore esterno</InputLabel>
                <Select
                    labelId="colore_esterno"
                    value={(formik.values.tipoColoreEsterno == "ral_standard" ? formik.values.coloreEsterno : formik.values.tipoColoreEsterno) ?? ""}
                    label={"Colore esterno"}
                    onChange={(e) => {
                        let value = e.target.value;
                        let externalColorType: ColorType = "ral_standard";
                        let externalColorCode: string | undefined = undefined;
                        if (value == "ral_standard" || value == "ral_non_standard") {
                            externalColorType = value as ColorType;
                            externalColorCode = undefined;
                        } else {
                            externalColorCode = value;
                        }

                        let values: ExternalDoorConfiguration = {
                            ...formik.values,
                            tipoColoreEsterno: externalColorType,
                            coloreEsterno: externalColorCode,
                        };
                        dispatcher(setPortinaAffiancata(values));
                        formik.setValues(values);
                    }}>
                    {renderStandardColorTypesMenuItems("external")}
                    <MenuItem value={"ral_non_standard"}>Colore personalizzato</MenuItem>
                </Select>
            </FormControl>
        );
    }

    const renderInputExternalNsRal = () => {
        if (formik.values.tipoColoreEsterno != "ral_non_standard") return null;

        return (
            <TextField
                fullWidth
                focused={false}
                className="mb-3"
                label={formik.values.coloreEsterno ? "" : "Seleziona colore"}
                name={"coloreEsterno"}
                value={finishesProvider?.getRalName(formik.values.coloreEsterno)}
                InputProps={{
                    readOnly: true,
                    startAdornment: formik.values.coloreEsterno && (
                        <InputAdornment position="start">
                            {finishesProvider?.getRalPreview(formik.values.coloreEsterno)}
                        </InputAdornment>
                    ),

                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={() => dialogs?.selectRalColor({
                                onConfirm: selectedColor => {
                                    let values: ExternalDoorConfiguration = {
                                        ...formik.values,
                                        coloreEsterno: selectedColor,
                                    };
                                    dispatcher(setPortinaAffiancata(values));
                                    formik.setValues(values);
                                }
                            })}>
                                <OpenInNewIcon style={{ color: "black" }} />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        );
    }

    const renderInputInternalNsRal = () => {
        return (
            <TextField
                fullWidth
                focused={false}
                className="mb-3"
                label={"Colore interno"}
                name={"coloreInterno"}
                value={finishesProvider?.getRalName(formik.values.coloreInterno)}
                InputProps={{
                    readOnly: true,
                    startAdornment: formik.values.coloreInterno && (
                        <InputAdornment position="start">
                            {finishesProvider?.getRalPreview(formik.values.coloreInterno)}
                        </InputAdornment>
                    )
                }}
            />
        );
    }

    const renderSelectExternalFrameColorType = () => {
        return (
            <FormControl fullWidth className="mt-3">
                <InputLabel id="colore_telaio_esterno">Colore telaio esterno</InputLabel>
                <Select
                    labelId="colore_telaio_esterno"
                    value={getColorTypeValue("external")}
                    label={"Colore telaio esterno"}
                    onChange={(e) => {
                        let value = e.target.value;
                        let externalFrameColorType: ColorType = "ral_standard";
                        let externalFrameColorCode: string | undefined = undefined;
                        if (value == "ral_standard" || value == "ral_non_standard") {
                            externalFrameColorType = value as ColorType;
                            externalFrameColorCode = undefined;
                        } else {
                            externalFrameColorCode = value;
                        }

                        let values: ExternalDoorConfiguration = {
                            ...formik.values,
                            externalFrameColorType,
                            externalFrameColorCode,
                            internalFrameColorType: externalFrameColorType,
                            internalFrameColorCode: externalFrameColorCode
                        };
                        dispatcher(setPortinaAffiancata(values));
                        formik.setValues(values);
                    }}>
                    {renderStandardColorTypesMenuItems("external")}
                    <MenuItem value={"a_campione"}>Colore a campione</MenuItem>
                    <MenuItem value={"ral_non_standard"}>Colore personalizzato</MenuItem>
                </Select>
            </FormControl>
        );
    }

    const renderInputExternalFrameNsRal = () => {
        if (formik.values.externalFrameColorType != "ral_non_standard") return null;

        return (
            <TextField
                fullWidth
                focused={false}
                className="mt-1"
                label={formik.values.externalFrameColorCode ? "" : "Seleziona colore"}
                name={"coloreTelaioEsterno"}
                value={finishesProvider?.getRalName(formik.values.externalFrameColorCode)}
                InputProps={{
                    readOnly: true,
                    startAdornment: formik.values.externalFrameColorCode && (
                        <InputAdornment position="start">
                            {finishesProvider?.getRalPreview(formik.values.externalFrameColorCode)}
                        </InputAdornment>
                    ),

                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={() => dialogs?.selectRalColor({
                                onConfirm: selectedColor => {
                                    let values: ExternalDoorConfiguration = {
                                        ...formik.values,
                                        externalFrameColorCode: selectedColor,
                                        internalFrameColorCode: selectedColor,
                                    };
                                    dispatcher(setPortinaAffiancata(values));
                                    formik.setValues(values);
                                }
                            })}>
                                <OpenInNewIcon style={{ color: "black" }} />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        );
    }

    const renderSelectInternalFrameColorType = () => {
        return (
            <FormControl fullWidth className="mt-3">
                <InputLabel id="colore_telaio_interno">Colore telaio interno</InputLabel>
                <Select
                    labelId="colore_telaio_interno"
                    value={getColorTypeValue("internal")}
                    label={"Colore telaio interno"}
                    onChange={(e) => {
                        let value = e.target.value;
                        let internalFrameColorType: ColorType = "ral_standard";
                        let internalFrameColorCode: string | undefined = undefined;
                        if (value == "ral_standard" || value == "ral_non_standard") {
                            internalFrameColorType = value as ColorType;
                            internalFrameColorCode = undefined;
                        } else {
                            internalFrameColorCode = value;
                        }

                        let values: ExternalDoorConfiguration = {
                            ...formik.values,
                            internalFrameColorType,
                            internalFrameColorCode
                        };
                        dispatcher(setPortinaAffiancata(values));
                        formik.setValues(values);
                    }}>
                    {renderStandardColorTypesMenuItems("internal")}
                    <MenuItem value={"a_campione"}>Colore a campione</MenuItem>
                    <MenuItem value={"ral_non_standard"}>Colore personalizzato</MenuItem>
                </Select>
            </FormControl>
        );
    }

    const renderInputInternalFrameNsRal = () => {
        if (formik.values.internalFrameColorType != "ral_non_standard") return null;

        return (
            <TextField
                fullWidth
                focused={false}
                className="mt-1"
                label={formik.values.internalFrameColorCode ? "" : "Seleziona colore"}
                name={"coloreTelaioInternoNsRal"}
                value={finishesProvider?.getRalName(formik.values.internalFrameColorCode)}
                InputProps={{
                    readOnly: true,
                    startAdornment: formik.values.internalFrameColorCode && (
                        <InputAdornment position="start">
                            {finishesProvider?.getRalPreview(formik.values.internalFrameColorCode)}
                        </InputAdornment>
                    ),

                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={() => dialogs?.selectRalColor({
                                onConfirm: selectedColor => {
                                    let values: ExternalDoorConfiguration = {
                                        ...formik.values,
                                        internalFrameColorCode: selectedColor
                                    };
                                    dispatcher(setPortinaAffiancata(values));
                                    formik.setValues(values);
                                }
                            })}>
                                <OpenInNewIcon style={{ color: "black" }} />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        );
    }

    const renderSelectExternalHandleColorType = () => {
        if (!doorConfigurationBuilder.configuration) return null;

        return (
            <FormControl fullWidth className="mt-3">
                <InputLabel id="tipo_colore_maniglia_esterna">Colore maniglia esterna</InputLabel>
                <Select
                    labelId="tipo_colore_maniglia_esterna"
                    value={formik.values.externalHandleColorType ?? "ral_standard"}
                    label={"Colore maniglia esterna"}
                    onChange={(e) => {
                        let values: ExternalDoorConfiguration = {
                            ...formik.values,
                            externalHandleColorType: e.target.value as ColorType,
                            externalHandleColorCode: undefined
                        };
                        dispatcher(setPortinaAffiancata(values));
                        formik.setValues(values);
                    }}>
                    <MenuItem value={"ral_standard"}>Nero</MenuItem>
                    <MenuItem value={"ral_non_standard"}>Colore personalizzato</MenuItem>
                </Select>
            </FormControl>
        );
    }

    const renderInputExternalHandleNsRal = () => {
        return (
            <TextField
                fullWidth 
                focused={false}
                className="mt-3"
                label={"Specificare colore maniglia esterna"}
                name={"coloreManigliaEsterna"}
                value={finishesProvider?.getRalName(formik.values.externalHandleColorCode)}
                InputProps={{
                    readOnly: true, 
                    startAdornment: formik.values.externalHandleColorCode && (
                        <InputAdornment position="start">
                            {finishesProvider?.getRalPreview(formik.values.externalHandleColorCode)}
                        </InputAdornment>
                    ),

                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={() => dialogs?.selectRalColor({onConfirm: selectedColor => {                                
                                let values: ExternalDoorConfiguration = {
                                    ...formik.values,
                                    externalHandleColorCode: selectedColor
                                };
                                dispatcher(setPortinaAffiancata(values));
                                formik.setValues(values);
                            }})}>
                                <OpenInNewIcon style={{ color: "black" }} />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        );
    }

    const renderSelectInternalHandleColorType = () => {
        if (!doorConfigurationBuilder.configuration) return null;

        return (
            <FormControl fullWidth className="mt-3">
                <InputLabel id="tipo_colore_maniglia_interna">Colore maniglia interna</InputLabel>
                <Select
                    labelId="tipo_colore_maniglia_interna"
                    value={formik.values.internalHandleColorType ?? "ral_standard"}
                    label={"Colore maniglia interna"}
                    onChange={(e) => {
                        let values: ExternalDoorConfiguration = {
                            ...formik.values,
                            internalHandleColorType: e.target.value as ColorType,
                            internalHandleColorCode: undefined
                        };
                        dispatcher(setPortinaAffiancata(values));
                        formik.setValues(values);
                    }}>
                    <MenuItem value={"ral_standard"}>Nero</MenuItem>
                    <MenuItem value={"ral_non_standard"}>Colore personalizzato</MenuItem>
                </Select>
            </FormControl>
        );
    }

    const renderInputInternalHandleNsRal = () => {
        return (
            <TextField
                fullWidth 
                focused={false}
                className="mt-3"
                label={"Specificare colore maniglia interna"}
                name={"coloreManigliaInterna"}
                value={finishesProvider?.getRalName(formik.values.internalHandleColorCode)}
                InputProps={{
                    readOnly: true, 
                    startAdornment: formik.values.internalHandleColorCode && (
                        <InputAdornment position="start">
                            {finishesProvider?.getRalPreview(formik.values.internalHandleColorCode)}
                        </InputAdornment>
                    ),

                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={() => dialogs?.selectRalColor({onConfirm: selectedColor => {                                
                                let values: ExternalDoorConfiguration = {
                                    ...formik.values,
                                    internalHandleColorCode: selectedColor
                                };
                                dispatcher(setPortinaAffiancata(values));
                                formik.setValues(values);
                            }})}>
                                <OpenInNewIcon style={{ color: "black" }} />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        );
    }

    const getColorTypeValue = (colorSide: ColorSide): string => {
        if (colorSide == "external") {
            if (formik.values.externalFrameColorType == "ral_standard") {
                return formik.values.externalFrameColorCode ?? "";
            }
            return formik.values.externalFrameColorType;
        } else {
            if (formik.values.internalFrameColorType == "ral_standard") {
                return formik.values.internalFrameColorCode ?? "";
            }
            return formik.values.internalFrameColorType;
        }
    }

    const renderStandardColorTypesMenuItems = (colorSide: ColorSide) => {
        let colors: string[] = [];

        if (!doorConfigurationBuilder.isLaminato() && !doorConfigurationBuilder.isPietravera() && !doorConfigurationBuilder.isColoreCampione()) {
            let externalColorCode = doorConfigurationBuilder.getExternalColorCode();
            if (externalColorCode && !colors.includes(externalColorCode)) colors.push(externalColorCode);
        }

        if (isFullglass()) {
            let arColors = getColors("external", formik.values.finitura?.id);
            let standardColors = arColors.filter(c => c?.code != doorConfigurationBuilder.getExternalColorCode()).map(c => c?.code ?? "");
            colors = colors.concat(standardColors);
        }

        return colors.map(c => (
            <MenuItem value={c}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                    {getRalName(c)}
                    {renderFinishPreview(c)}
                </div>
            </MenuItem>
        ));
    }

    const getRalName = (colorCode: NullableString) => {
        if (!colorCode) return "";

        let color = doorConfigurationBuilder.colors.find(c => c?.code == colorCode);
        return color ? `${color.name} (${color.code})` : finishesProvider?.getRalName(colorCode);
    }

    const getRalNameByColorId = (colorId: NullableNumber) => {
        if (!colorId) return "";

        let color = doorConfigurationBuilder.colors.find(c => c?.id == colorId);
        return color ? color.name + ` (${color.code})` : undefined;
    }

    const renderFinishPreview = (colorCode: NullableString) => {
        if (!colorCode) return null;

        let preview = finishes.find(f => f.code == colorCode)?.img;
        if (preview) return <img src={preview} style={{ width: "2rem", height: "1.75rem" }} />;

        preview = rals.find(f => f.RAL == colorCode)?.HEX;
        if (preview) return <div style={{ width: "1.75rem", height: "1.75rem", backgroundColor: preview }}></div>;

        return null;
    }

    const renderFinishPreviewByColorId = (colorId: NullableNumber) => {
        if (!colorId) return null;

        let color = doorConfigurationBuilder.colors.find(c => c?.id == colorId);
        if (!color) return null;

        let preview = finishes.find(f => f.code == color?.code)?.img;
        if (preview) return <img src={preview} style={{ width: "2rem", height: "1.75rem" }} />;

        preview = rals.find(f => f.RAL == color?.code)?.HEX;
        if (preview) return <div style={{ width: "1.75rem", height: "1.75rem", backgroundColor: preview }}></div>;

        return null;
    }

    useImperativeHandle(ref, () => ({
        async getData(): Promise<ExternalDoorConfiguration> {
            if (formik.values.larghezza < 900 || formik.values.larghezza > 1300) {
                formik.setFieldError("larghezza", "La larghezza della porta deve essere compresa tra 900 e 1300 mm");
                throw { severity: "warning", message: "" };
            }
            if (formik.values.altezza < 1900 || formik.values.altezza > 2500) {
                const msg = "L'altezza della porta deve essere compresa tra 1900 e 2500 mm";
                formik.setFieldError("altezza", msg);
                throw { severity: "warning", message: msg };
            }
            if (!formik.values.usaStessaFinitura) {
                if (!formik.values.porta) {
                    const msg = "Selezionare il tipo di porta";
                    formik.setFieldError("porta", msg);
                    throw { severity: "warning", message: msg };
                }
                if (!formik.values.modello) {
                    const msg = "Selezionare il modello";
                    formik.setFieldError("modello", msg);
                    throw { severity: "warning", message: msg };
                }
                if (!formik.values.finitura) {
                    const msg = "Selezionare la finitura";
                    formik.setFieldError("finitura", msg);
                    throw { severity: "warning", message: msg };
                }
                if (!isFullglass() && formik.values.tipoColoreEsterno == "ral_standard" && !formik.values.coloreEsterno) throw { severity: "warning", message: "Seleziona il colore esterno" };
                if (!isFullglass() && formik.values.tipoColoreEsterno == "ral_non_standard" && !formik.values.coloreEsterno) throw { severity: "warning", message: "Inserisci il RAL da usare come colore esterno" };
            }

            if (!formik.values.externalFrameColorCode) {
                const msg = "Selezionare il colore del telaio esterno della porta";
                formik.setFieldError("externalFrameColorCode", msg);
                throw { severity: "warning", message: msg };
            }
            if (!formik.values.internalFrameColorCode) {
                const msg = "Selezionare il colore del telaio interno della porta";
                formik.setFieldError("internalFrameColorCode", msg);
                throw { severity: "warning", message: msg };
            }

            return formik.values;
        }
    }));

    const initDefaults = () => {
        if (doorConfigurationBuilder.isLaminato() || doorConfigurationBuilder.isPietravera() || doorConfigurationBuilder.isColoreCampione()) {
            formik.setValues({
                ...formik.values,
                externalFrameColorType: "ral_non_standard",
                externalFrameColorCode: undefined,
                internalFrameColorType: "ral_non_standard",
                internalFrameColorCode: undefined,
            });
        } else {
            let values: ExternalDoorConfiguration = {
                ...formik.values,
                externalFrameColorType: doorConfigurationBuilder.getExternalColorType(),
                externalFrameColorCode: doorConfigurationBuilder.getExternalColorCode()
            };

            values.internalFrameColorType = values.externalFrameColorType;
            values.internalFrameColorCode = values.externalFrameColorCode;

            if (doorConfigurationBuilder.isWoodstyle()) {
                if (doorConfigurationBuilder.getExternalColorCode() == ColorCode.tinta_chiara) {
                    values.externalFrameColorType = "ral_non_standard";
                    values.externalFrameColorCode = "8003";
                } else if (doorConfigurationBuilder.getExternalColorCode() == ColorCode.tinta_media) {
                    values.internalFrameColorType = "ral_non_standard";
                    values.internalFrameColorCode = "8007";
                }
            }

            formik.setValues(values);
        }
    }

    useEffect(() => {
        initDefaults();
    }, []);

    return (
        <div>
            <FormControlLabel
                className="w-full"
                control={
                    <Checkbox
                        checked={formik.values.portina}
                        onChange={(e) => {
                            let values: ExternalDoorConfiguration = {
                                ...formik.values,
                                portina: e.target.checked
                            };
                            formik.setValues(values);
                            dispatcher(setPortinaAffiancata(values));
                        }}
                        sx={{ marginTop: "0", marginBottom: "0" }}
                    />
                }
                label="Aggiungi portina"
                sx={{ marginTop: "0", marginBottom: "0", textTransform: "uppercase" }}
            />

            {formik.values.portina && renderFormPortina()}
        </div>
    );
});