export const PRIMARY_COLOR: string = "#df5b23";
export const SECONDARY_COLOR: string = "#212529";

export const DRAW_FACTOR: number = 0.1;
export const DEFAULT_SCENE_WIDTH: number = 1000;
export const DEFAULT_SCENE_HEIGHT: number = 1000;
export const DEFAULT_LARGHEZZA_PORTA_MIN: number = 2020;
export const DEFAULT_ALTEZZA_PORTA_MIN: number = 1930;
export const DEFAULT_LARGHEZZA_PORTA: number = 3270;
export const DEFAULT_ALTEZZA_PORTA: number = 2580;
export const DEFAULT_LARGHEZZA_PORTA_MAX: number = 6000;
export const DEFAULT_ALTEZZA_PORTA_MAX: number = 2980;
export const DEFAULT_COLORE_STIPITE: string = "#ccc";//"#6f8aa6";
export const DEFAULT_STROKE_COLOR: string = "#777";
export const DEFAULT_COLORE_PAVIMENTO: string = "#363636";
export const DEFAULT_STROKE_WIDTH: number = 1;
export const DEFAULT_METRIC_SIZE: number = 50;
export const DEFAULT_SUPERFICIE_PAVIMENTO: number = 1100;
export const DEFAULT_LARGHEZZA_COPRIFILO: number = 120;
export const DEFAULT_LARGHEZZA_GUARNIZIONE: number = 40;

export const ALTEZZA_ARCHITRAVE: number = 50;
export const SPESSORE_ARCHITRAVE: number = 50;
export const LARGHEZZA_SPALLETTA_SX: number = 50;
export const SPESSORE_SPALLETTA_SX: number = 50;
export const LARGHEZZA_SPALLETTA_DX: number = 50;
export const SPESSORE_SPALLETTA_DX: number = 50;
export const LARGHEZZA_GARAGE: number = 600;
export const ALTEZZA_GARAGE: number = 350;
export const PROFONDITA_INVOLUCRO: number = 150;
export const PROFONDITA_INVOLUCRO_INTERNO: number = 150;

export const ALTEZZA_FINESTRA: number = 36;
export const LARGHEZZA_FINESTRA: number = 54;
export const ALTEZZA_FINESTRA_ACCIAIO: number = 23;
export const LARGHEZZA_FINESTRA_ACCIAIO: number = 23;
export const SPESSORE_TELAIO_FINESTRA: number = 4;
export const SPESSORE_TELAIO_GLASSPANEL: number = 8;
export const ALTEZZA_GRIGLIA: number = 20;
export const LARGHEZZA_GRIGLIA: number = 40;
export const SPESSORE_TELAIO_GRIGLIA: number = 2;
export const DISTANZA_MINIMA_FINESTRA_GRIGLIA: number = 20;

export const ALTEZZA_PANNELLO_PORTA_CLASSIC: number = 50;
export const SPESSORE_STIPITE: number = 25;
export const LARGHEZZA_GUARNIZIONE: number = 4;

export const SPESSORE_MOSTRINA_PORTINA_INT: number = 5.5;
export const LARGHEZZA_PORTINA_INT_CLASSIC: number = 90;
export const LARGHEZZA_PORTINA_INT_900: number = 100;
export const LARGHEZZA_PORTINA_INT_1200: number = 130;