import React, { FC, useEffect, useState } from "react";
import { Group, Image, Layer } from "react-konva";
import { useStoreSelector } from "../../../../../../../../hooks/StoreHooks";
import { Vector2d } from "konva/lib/types";
import { Color, Option } from "../../../../../../../models/door_configuration";
import { PortaSezionaleClassic } from "../door-models/porta_sezionale_classic";
import { ColorCode, DoorType } from "../../../../../../../core/common_types";
import { PortaSezionalePlana } from "../door-models/porta_sezionale_plana";
import { PortaSezionaleFullVision } from "../door-models/porta_sezionale_full_vision";
import { PannelloRect } from "../../../../../../../core/common_draw_types";
import { toDrawUnit } from "../../../../../../../core/common_functions";
import { Vector } from "vecti";
import { useFinishes } from "../../../../../../../providers/finish_provider";
import { PortinaIntegrata } from "../accessories/portina_integrata";

export const DoorDetails: FC<{
    options: Option[], 
    onPanelClicked?: (panelData: PannelloRect) => void}> = (props) => {
    const {options, onPanelClicked} = props;
    const {doorConfigurationBuilder} = useStoreSelector(store => store);

    const hf = toDrawUnit(doorConfigurationBuilder.configuration?.altezzaForo ?? 0);
    const lf = toDrawUnit(doorConfigurationBuilder.configuration?.larghezzaForo ?? 0);
    const hp = toDrawUnit(doorConfigurationBuilder.configuration?.altezzaPorta ?? 0);
    const lp = toDrawUnit(doorConfigurationBuilder.configuration?.larghezzaPorta ?? 0);
    const pf = Vector.of([lf/2, hf/2]);

    const finishesProvider = useFinishes();
    const [colors, setColors] = useState<{door: HTMLImageElement|undefined, windows: HTMLImageElement|undefined, grids: HTMLImageElement|undefined}|undefined>();

    const findPorta = () => {
        let option = options.find(o => o?.optionId == doorConfigurationBuilder.configuration?.porta);
        return option;
    }

    const findModello = () => {
        let option = options.find(o => o?.optionId == doorConfigurationBuilder.configuration?.modello);
        return option;
    }

    const findFinitura = () => {
        let option = options.find(o => o?.optionId == doorConfigurationBuilder.configuration?.finitura);
        return option;
    }

    const renderPorta = () => {
        if (!doorConfigurationBuilder.configuration) return null;
        if (!doorConfigurationBuilder.configuration.modello) return null;
        if (doorConfigurationBuilder.configuration.altezzaPorta <= 0) return null;
        if (doorConfigurationBuilder.configuration.larghezzaPorta <= 0) return null;

        let porta = findPorta();
        let modello = findModello();
        let finitura = findFinitura();

        if (porta?.name == DoorType.classic) return (
            <PortaSezionaleClassic
                pf={pf}
                hfdraw={hf} 
                lfdraw={lf} 
                hpdraw={hp} 
                lpdraw={lp} 
                modello={modello}
                doorColor={colors?.door}
                windowsColor={colors?.windows}
                gridsColor={colors?.grids}
                onPanelClicked={onPanelClicked}
            />
        );

        if (porta?.name == DoorType.plana) return (
            <PortaSezionalePlana
                pf={pf}
                hfdraw={hf} 
                lfdraw={lf} 
                hpdraw={hp} 
                lpdraw={lp} 
                finish={finitura}
                doorColor={colors?.door}
                windowsColor={colors?.windows}
                gridsColor={colors?.grids}
                onPanelClicked={onPanelClicked}
            />
        );

        if (porta?.name == DoorType.full_vision) return (
            <PortaSezionaleFullVision
                pf={pf}
                hfdraw={hf} 
                lfdraw={lf} 
                hpdraw={hp} 
                lpdraw={lp} 
                frameColor={colors?.door}
            />
        );
    }

    const renderPortina = () => {
        return (
            <PortinaIntegrata
                pf={pf}
                hf={hf} 
                lf={lf} 
                hp={hp} 
                lp={lp}
                doorColor={colors?.door}
            />
        );
    }

    const loadColors = async () => {  
        let door = await finishesProvider?.getImgRal(doorConfigurationBuilder.getExternalColorCode());
        let grids = await finishesProvider?.getImgRal(doorConfigurationBuilder.getGridColorCode());
        let windows = await finishesProvider?.getImgRal(doorConfigurationBuilder.getWindowFrameColorCode()); 
        
        setColors({door, windows, grids});
    }

    useEffect(() => {
        loadColors();
    }, [
        doorConfigurationBuilder.configuration?.coloreEsterno,
        doorConfigurationBuilder.configuration?.coloreEsternoNsRal, 
        doorConfigurationBuilder.configuration?.finestratura,
        doorConfigurationBuilder.configuration?.ventilazione
    ]);

    if (!colors) return null;

    return (
        <Layer>
            <Group x={0} y={0}>
                {renderPorta()}
                {doorConfigurationBuilder.configuration?.portinaIntegrata && renderPortina()}
                {/* <Image

                /> */}
            </Group>
        </Layer>
    );
}