import React, { FC, useEffect, useState } from "react";
import { Circle, Group, Line, Rect } from "react-konva";
import { distance, toDrawUnit, Vector2dToPointsArray, VectorToPointsArray } from "../../../../../../../core/common_functions";
import { useStoreSelector } from "../../../../../../../../hooks/StoreHooks";
import { InstallationType, OpeningDirection } from "../../../../../../../core/common_types";
import { Vector } from "vecti";
import { PROFONDITA_INVOLUCRO, SPESSORE_STIPITE, SPESSORE_MOSTRINA_PORTINA_INT, LARGHEZZA_PORTINA_INT_900, LARGHEZZA_PORTINA_INT_1200 } from "../../../../../../../core/common_constants";
import { IRect, Vector2d } from "konva/lib/types";
import { useFinishes } from "../../../../../../../providers/finish_provider";

const SPESSORE_TELAIO_PORTINA_INT: number = 2.8;

export const PortinaIntegrata: FC<{ pf: Vector, hp: number, lp: number, hf: number, lf: number, doorColor: HTMLImageElement|undefined }> = (props) => {
    const { hp, lp, hf, lf, pf, doorColor } = props;    
    const { doorConfigurationBuilder } = useStoreSelector(store => store);
    const finishesProvider = useFinishes();
    const [colors, setColors] = useState<{frame: HTMLImageElement|undefined, externalHandle: HTMLImageElement|undefined}|undefined>();

    const getLarghezzaPortinaDefault = () => {
        let larghezza: number = 0;
    
        if (doorConfigurationBuilder.configuration?.portinaIntegrata?.fuoriMisura == "900") {
            larghezza = LARGHEZZA_PORTINA_INT_900;
        } else if (doorConfigurationBuilder.configuration?.portinaIntegrata?.fuoriMisura == "1200") {
            larghezza = LARGHEZZA_PORTINA_INT_1200;
        } else {
            larghezza = toDrawUnit(doorConfigurationBuilder.configuration?.portinaIntegrata?.larghezza?.esternoTelaio ?? 0);
        }
        return larghezza;
    }

    const getOrg = (): Vector => {
        let org = Vector.of([(lf / 2), hf]);

        if (doorConfigurationBuilder.configuration?.portinaIntegrata?.posizione == "sx") {
            org = Vector.of([(getLarghezzaPortinaDefault()/2) + 50, hf]);
        } else if (doorConfigurationBuilder.configuration?.portinaIntegrata?.posizione == "dx") {
            org = Vector.of([lf-(getLarghezzaPortinaDefault()/2) - 50, hf]);
        }

        if (!doorConfigurationBuilder.isOltreLuce()) return org;
        
        let dir = pf.subtract(org).normalize();
        let newOrg = org.add(dir.multiply(SPESSORE_STIPITE));

        let psx = Vector.of([0, hf]);
        dir = pf.subtract(psx).normalize();
        psx = psx.add(dir.multiply(SPESSORE_STIPITE));

        return Vector.of([newOrg.x, psx.y]);
    }

    const getAltezzaPortina = (): number => {
        const altezzaPortina = toDrawUnit(doorConfigurationBuilder.configuration?.portinaIntegrata?.altezza?.esternoTelaio ?? 0);

        if (!doorConfigurationBuilder.isOltreLuce()) return altezzaPortina;
        
        let psxT = Vector.of([0, hf-altezzaPortina]);
        let dir = pf.subtract(psxT).normalize();
        psxT = psxT.add(dir.multiply(SPESSORE_STIPITE));

        let psxB = Vector.of([0, hf]);
        dir = pf.subtract(psxB).normalize();
        psxB = psxB.add(dir.multiply(SPESSORE_STIPITE));

        return distance(psxT.x, psxT.y, psxB.x, psxB.y);
    }

    const getLarghezzaPortina = (): number => {
        const larghezzaPortina = getLarghezzaPortinaDefault();

        if (!doorConfigurationBuilder.isOltreLuce()) return larghezzaPortina;

        let psx = Vector.of([0, hf]);
        let dir = pf.subtract(psx).normalize();
        psx = psx.add(dir.multiply(SPESSORE_STIPITE));

        let pdx = Vector.of([larghezzaPortina, hf]);
        dir = pf.subtract(pdx).normalize();
        pdx = pdx.add(dir.multiply(SPESSORE_STIPITE));

        return distance(psx.x, psx.y, pdx.x, pdx.y);
    }

    const loadColors = async () => {  
        let frame = doorConfigurationBuilder.configuration?.portinaIntegrata?.externalFrameColorType != "ral_standard" ? await finishesProvider?.getImgRal(doorConfigurationBuilder.configuration?.portinaIntegrata?.externalFrameColorCode) : doorColor;
        let externalHandle = doorConfigurationBuilder.configuration?.portinaIntegrata?.externalHandleColorType != "ral_standard" ? await finishesProvider?.getImgRal(doorConfigurationBuilder.configuration?.portinaIntegrata?.externalHandleColorCode) : undefined; 
        
        setColors({frame, externalHandle});
    }

    useEffect(() => {
        loadColors();
    }, [doorConfigurationBuilder.configuration?.portinaIntegrata]);

    if (!colors) return null;

    return (
        <Group>
            <TelaioPortinaIntegrata org={getOrg()} altezzaPortina={getAltezzaPortina()} larghezzaPortina={getLarghezzaPortina()} spessoreTelaio={SPESSORE_TELAIO_PORTINA_INT} frameColor={colors.frame} />
            <Portina pf={pf} org={getOrg()} altezzaPortina={getAltezzaPortina()} larghezzaPortina={getLarghezzaPortina()} spessoreTelaio={SPESSORE_TELAIO_PORTINA_INT} frameColor={colors.frame} />
            <Guarnizioni pf={pf} org={getOrg()} altezzaPortina={getAltezzaPortina()} larghezzaPortina={getLarghezzaPortina()} spessoreTelaio={SPESSORE_TELAIO_PORTINA_INT} />
            <Maniglia pf={pf} org={getOrg()} altezzaPortina={getAltezzaPortina()} larghezzaPortina={getLarghezzaPortina()} spessoreTelaio={SPESSORE_TELAIO_PORTINA_INT} pos={doorConfigurationBuilder.configuration?.portinaIntegrata?.apertura ?? "sx"} externalHandleColor={colors.externalHandle} />

            {/* <Circle {...pf} radius={4} fill={"red"} />
            <Circle {...getOrg()} radius={4} fill={"red"} /> */}
        </Group>
    );
}

const TelaioPortinaIntegrata: FC<{ org: Vector, altezzaPortina: number, larghezzaPortina: number, spessoreTelaio: number, frameColor: HTMLImageElement|undefined }> = (props) => {
    const { org, altezzaPortina, larghezzaPortina, spessoreTelaio, frameColor } = props;

    const getPointsTelaioSx = (): Vector[] => {        
        let ptBottomLeft = Vector.of([org.x - (larghezzaPortina / 2), org.y]);
        let ptTopLeft = Vector.of([ptBottomLeft.x, org.y - altezzaPortina]);
        let ptTopRight = Vector.of([ptBottomLeft.x + spessoreTelaio, ptTopLeft.y + spessoreTelaio]);
        let ptBottomRight = Vector.of([ptTopRight.x, org.y - spessoreTelaio]);

        return [
            ptBottomLeft,
            ptTopLeft,
            ptTopRight,
            ptBottomRight
        ];
    }

    const getPointsTelaioDx = (): Vector[] => {
        let ptTopRight = Vector.of([org.x + (larghezzaPortina / 2), org.y - altezzaPortina]);
        let ptBottomRight = Vector.of([ptTopRight.x, org.y]);
        let ptBottomLeft = Vector.of([ptBottomRight.x - spessoreTelaio, org.y - spessoreTelaio]);
        let ptTopLeft = Vector.of([ptBottomLeft.x, ptTopRight.y + spessoreTelaio]);

        return [
            ptBottomLeft,
            ptTopLeft,
            ptTopRight,
            ptBottomRight
        ];
    }

    const getPointsTelaioSup = (): Vector[] => {
        let ptTopLeft = Vector.of([org.x - (larghezzaPortina / 2), org.y-altezzaPortina]);
        let ptTopRight = Vector.of([ptTopLeft.x + larghezzaPortina, ptTopLeft.y]);
        let ptBottomRight = Vector.of([ptTopRight.x - spessoreTelaio, ptTopLeft.y + spessoreTelaio]);
        let ptBottomLeft = Vector.of([ptTopLeft.x + spessoreTelaio, ptBottomRight.y]);

        return [
            ptBottomLeft,
            ptTopLeft,
            ptTopRight,
            ptBottomRight
        ];
    }

    const getPointsTelaioInf = (): Vector[] => {
        let ptBottomLeft = Vector.of([org.x - (larghezzaPortina/2), org.y]);
        let ptTopLeft = Vector.of([ptBottomLeft.x + spessoreTelaio, ptBottomLeft.y - spessoreTelaio]);
        let ptTopRight = Vector.of([org.x + (larghezzaPortina/2) - spessoreTelaio, ptTopLeft.y]);    
        let ptBottomRight = Vector.of([org.x + (larghezzaPortina/2), ptBottomLeft.y]);

        return [
            ptBottomLeft,
            ptTopLeft,
            ptTopRight,
            ptBottomRight
        ];
    }

    return (
        <Group>
            <Line
                points={VectorToPointsArray(getPointsTelaioSx())}
                stroke={"#000"}
                strokeWidth={0.5}
                fillPatternImage={frameColor}
                closed
            />
            <Line
                points={VectorToPointsArray(getPointsTelaioDx())}
                stroke={"#000"}
                strokeWidth={0.5}
                fillPatternImage={frameColor}
                closed
            />
            <Line
                points={VectorToPointsArray(getPointsTelaioSup())}
                stroke={"#000"}
                strokeWidth={0.5}
                fillPatternImage={frameColor}
                closed
            />
            <Line
                points={VectorToPointsArray(getPointsTelaioInf())}
                stroke={"#000"}
                strokeWidth={0.5}
                fillPatternImage={frameColor}
                closed
            />            
        </Group>
    );
}

const Portina: FC<{ pf: Vector, org: Vector, altezzaPortina: number, larghezzaPortina: number, spessoreTelaio: number, frameColor: HTMLImageElement|undefined }> = (props) => {
    const { org, altezzaPortina, larghezzaPortina, spessoreTelaio, frameColor } = props;

    const getPointsTelaioSx = (): Vector[] => {
        let ptBottomLeft = Vector.of([org.x - (larghezzaPortina / 2) + spessoreTelaio, org.y - spessoreTelaio]);
        let ptTopLeft = Vector.of([ptBottomLeft.x, org.y - altezzaPortina + spessoreTelaio]);
        let ptTopRight = Vector.of([ptBottomLeft.x + SPESSORE_MOSTRINA_PORTINA_INT, ptTopLeft.y + SPESSORE_MOSTRINA_PORTINA_INT]);
        let ptBottomRight = Vector.of([ptTopRight.x, org.y - SPESSORE_MOSTRINA_PORTINA_INT - spessoreTelaio]);

        return [
            ptBottomLeft,
            ptTopLeft,
            ptTopRight,
            ptBottomRight
        ];
    }

    const getPointsTelaioDx = (): Vector[] => {
        let ptTopRight = Vector.of([org.x + (larghezzaPortina / 2) - spessoreTelaio, org.y - altezzaPortina + spessoreTelaio]);
        let ptBottomRight = Vector.of([ptTopRight.x, org.y - spessoreTelaio]);
        let ptBottomLeft = Vector.of([ptBottomRight.x - SPESSORE_MOSTRINA_PORTINA_INT, org.y - SPESSORE_MOSTRINA_PORTINA_INT - spessoreTelaio]);
        let ptTopLeft = Vector.of([ptBottomLeft.x, ptTopRight.y + SPESSORE_MOSTRINA_PORTINA_INT]);

        return [
            ptBottomLeft,
            ptTopLeft,
            ptTopRight,
            ptBottomRight
        ];
    }

    const getPointsTelaioSup = (): Vector[] => {
        let ptTopLeft = Vector.of([org.x - (larghezzaPortina / 2) + spessoreTelaio, org.y - altezzaPortina + spessoreTelaio]);
        let ptTopRight = Vector.of([org.x + (larghezzaPortina / 2) - spessoreTelaio, ptTopLeft.y]);
        let ptBottomRight = Vector.of([ptTopRight.x - SPESSORE_MOSTRINA_PORTINA_INT, ptTopLeft.y + SPESSORE_MOSTRINA_PORTINA_INT]);
        let ptBottomLeft = Vector.of([ptTopLeft.x + SPESSORE_MOSTRINA_PORTINA_INT, ptBottomRight.y]);

        return [
            ptBottomLeft,
            ptTopLeft,
            ptTopRight,
            ptBottomRight
        ];
    }

    const getPointsTelaioInf = (): Vector[] => {
        let ptBottomRight = Vector.of([org.x + (larghezzaPortina / 2) - spessoreTelaio, org.y - spessoreTelaio]);
        let ptBottomLeft = Vector.of([org.x - (larghezzaPortina / 2) + spessoreTelaio, ptBottomRight.y]);
        let ptTopLeft = Vector.of([ptBottomLeft.x + SPESSORE_MOSTRINA_PORTINA_INT, ptBottomLeft.y - SPESSORE_MOSTRINA_PORTINA_INT]);
        let ptTopRight = Vector.of([ptBottomRight.x - SPESSORE_MOSTRINA_PORTINA_INT, ptTopLeft.y]);

        return [
            ptBottomLeft,
            ptTopLeft,
            ptTopRight,
            ptBottomRight
        ];
    }

    return (
        <Group>
            <Line
                points={VectorToPointsArray(getPointsTelaioSx())}
                stroke={"#000"}
                strokeWidth={0.5}
                fillPatternImage={frameColor}
                closed
            />
            <Line
                points={VectorToPointsArray(getPointsTelaioDx())}
                stroke={"#000"}
                strokeWidth={0.5}
                fillPatternImage={frameColor}
                closed
            />
            <Line
                points={VectorToPointsArray(getPointsTelaioSup())}
                stroke={"#000"}
                strokeWidth={0.5}
                fillPatternImage={frameColor}
                closed
            />
            <Line
                points={VectorToPointsArray(getPointsTelaioInf())}
                stroke={"#000"}
                strokeWidth={0.5}
                fillPatternImage={frameColor}
                closed
            />
        </Group>
    );
}

const Guarnizioni: FC<{ pf: Vector, org: Vector, larghezzaPortina: number, altezzaPortina: number, spessoreTelaio: number }> = (props) => {
    const { org, larghezzaPortina, altezzaPortina, spessoreTelaio } = props;

    const getBboxGuarnizione = (): IRect => {
        return {
            x: org.x - (larghezzaPortina/2) + spessoreTelaio,
            y: org.y - altezzaPortina + spessoreTelaio,
            width: larghezzaPortina - (2*spessoreTelaio),
            height: altezzaPortina - (2*spessoreTelaio)
        };
    }

    return (
        <Rect {...getBboxGuarnizione()} strokeWidth={0.75} stroke={"#000"} />
    );
}

const Maniglia: FC<{ pf: Vector, org: Vector, larghezzaPortina: number, altezzaPortina: number, spessoreTelaio: number, pos: OpeningDirection, externalHandleColor: HTMLImageElement|undefined }> = (props) => {
    const { org, larghezzaPortina, altezzaPortina, spessoreTelaio, pos, externalHandleColor } = props;

    const getPosManiglia = (): Vector => {
        if (pos == "dx") return Vector.of([
            org.x - (larghezzaPortina/2) + (2*spessoreTelaio),
            org.y - (altezzaPortina/2) - 1
        ]) 
        
        return Vector.of([
            org.x + (larghezzaPortina/2) - (2*spessoreTelaio) - 12,
            org.y - (altezzaPortina/2) - 1
        ]);
    }

    const getPosCopriManiglia = (): Vector => {
        if (pos == "dx") return Vector.of([
            org.x - (larghezzaPortina/2) + (2*spessoreTelaio),
            org.y - (altezzaPortina/2) - 2.5
        ]);

        return Vector.of([
            org.x + (larghezzaPortina/2) - (2*spessoreTelaio) - 2.5,
            org.y - (altezzaPortina/2) - 2.5
        ]);
    }

    const getPosCilindro = (): Vector => {
        if (pos == "dx") return Vector.of([
            org.x - (larghezzaPortina/2) + (2*spessoreTelaio) + 0.75,
            org.y - (altezzaPortina/2) + 6
        ]);

        return Vector.of([
            org.x + (larghezzaPortina/2) - (2*spessoreTelaio) - 0.75,
            org.y - (altezzaPortina/2) + 6
        ]);
    }
    
    return (
        <Group>
            <Rect
                {...getPosCopriManiglia()}
                width={2.5}
                height={5}
                fill={externalHandleColor == undefined ? "#000" : undefined}
                fillPatternImage={externalHandleColor != undefined ? externalHandleColor : undefined }
                cornerRadius={1}
                stroke={"#000"}
                strokeWidth={0.25}
            />
            <Rect
                {...getPosManiglia()}
                width={12}
                height={2}
                fill={externalHandleColor == undefined ? "#000" : undefined}
                fillPatternImage={externalHandleColor != undefined ? externalHandleColor : undefined }
                cornerRadius={0.5}
                stroke={"#000"}
                strokeWidth={0.25}
            />
            <Circle
                {...getPosCilindro()}
                radius={1.5}
                fill={externalHandleColor == undefined ? "#000" : undefined}
                fillPatternImage={externalHandleColor != undefined ? externalHandleColor : undefined }
                stroke={"#000"}
                strokeWidth={0.25}
            />
            <Circle
                {...getPosCilindro()}
                radius={0.75}
                fill={externalHandleColor == undefined ? "#000" : undefined}
                fillPatternImage={externalHandleColor != undefined ? externalHandleColor : undefined }
                stroke={"#000"}
                strokeWidth={0.25}
            />
        </Group>
    );
}