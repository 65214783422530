import React, { FC, useEffect, useState } from "react";
import { Group, Image, Layer } from "react-konva";
import { useStoreSelector } from "../../../../../../../../hooks/StoreHooks";
import { Vector2d } from "konva/lib/types";
import { Color, Option } from "../../../../../../../models/door_configuration";
import { PortaSezionaleClassic } from "../door-models/porta_sezionale_classic";
import { ColorCode, DoorType } from "../../../../../../../core/common_types";
import { PortaSezionalePlana } from "../door-models/porta_sezionale_plana";
import { PortaSezionaleFullVision } from "../door-models/porta_sezionale_full_vision";
import { PannelloRect } from "../../../../../../../core/common_draw_types";
import { toDrawUnit } from "../../../../../../../core/common_functions";
import { Vector } from "vecti";
import { useFinishes } from "../../../../../../../providers/finish_provider";
import { PortinaAffiancata } from "../accessories/portina_affiancata";

export const ExternalDoor: FC<{options: Option[]}> = (props) => {
    const {options} = props;
    const {doorConfigurationBuilder} = useStoreSelector(store => store);

    const hp = toDrawUnit(doorConfigurationBuilder.configuration?.altezzaForo ?? 0);
    const lp = toDrawUnit(doorConfigurationBuilder.configuration?.larghezzaForo ?? 0);
    const pf = Vector.of([lp/2, hp/2]);

    return (
        <Layer>
            <Group x={0} y={0}>
                <PortinaAffiancata hp={hp} lp={lp} pf={pf} />    
            </Group>
        </Layer>
    );
}