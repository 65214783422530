import { IRect } from "konva/lib/types";
import { ALTEZZA_FINESTRA, ALTEZZA_FINESTRA_ACCIAIO, ALTEZZA_GRIGLIA, DISTANZA_MINIMA_FINESTRA_GRIGLIA, LARGHEZZA_FINESTRA, LARGHEZZA_FINESTRA_ACCIAIO, LARGHEZZA_GRIGLIA } from "../../../../../../core/common_constants";
import { NullableString } from "../../../../../../core/common_types";
import { WindowSize } from "../../../../../../models/door_configuration";

export function calcolaNumeroFinestreDaLarghezzaPorta(larghezzaPorta: number): number {
    let res = 2;
    if (larghezzaPorta > 2410 && larghezzaPorta <= 3150) {
        res = 3;
    } else if (larghezzaPorta > 3150 && larghezzaPorta <= 3890) {
        res = 4;
    } else if (larghezzaPorta > 3890 && larghezzaPorta <= 4630) {
        res = 5;
    } else if (larghezzaPorta > 4630 && larghezzaPorta <= 5370) {
        res = 6;
    } else if (larghezzaPorta > 5370 && larghezzaPorta <= 6000) {
        res = 7;
    }
    return res;
}

export function calcolaNumeroGriglieDaLarghezzaPorta(larghezzaPorta: number): number {
    let res = 3;
    if (larghezzaPorta > 2590 && larghezzaPorta <= 3190) {
        res = 4;
    } else if (larghezzaPorta > 3190 && larghezzaPorta <= 3790) {
        res = 5;
    } else if (larghezzaPorta > 3790 && larghezzaPorta <= 4390) {
        res = 6;
    } else if (larghezzaPorta > 4390 && larghezzaPorta <= 4990) {
        res = 7;
    } else if (larghezzaPorta > 4990 && larghezzaPorta <= 5590) {
        res = 8;
    } else if (larghezzaPorta > 5590 && larghezzaPorta <= 6000) {
        res = 9;
    }
    return res;
}

export function calcolaFinestreDaNumeroElementi(numeroElementi: number, larghezzaTotale: number, windowSize: WindowSize): IRect[] {
    let res: IRect[] = [];

    let larghezzaFinestra = windowSize == WindowSize._230x230 ? LARGHEZZA_FINESTRA_ACCIAIO : LARGHEZZA_FINESTRA;

    let lunghezzaMax = larghezzaTotale - (2 * DISTANZA_MINIMA_FINESTRA_GRIGLIA) - larghezzaFinestra;
    let interasse = lunghezzaMax / (numeroElementi - 1);

    let currentX: number = DISTANZA_MINIMA_FINESTRA_GRIGLIA;
    for (let i = 0; i < numeroElementi; i++) {
        res.push({
            x: currentX,
            y: 0,
            width: larghezzaFinestra,
            height: windowSize == WindowSize._230x230 ? ALTEZZA_FINESTRA_ACCIAIO : ALTEZZA_FINESTRA
        }); 

        currentX = currentX + interasse;
    }

    return res;
}

export function calcolaGriglieDaNumeroElementi(numeroElementi: number, larghezzaTotale: number): IRect[] {
    let res: IRect[] = [];

    let lunghezzaMax = larghezzaTotale - (2 * DISTANZA_MINIMA_FINESTRA_GRIGLIA) - LARGHEZZA_GRIGLIA;
    let interasse = lunghezzaMax / (numeroElementi - 1);

    let currentX: number = DISTANZA_MINIMA_FINESTRA_GRIGLIA;
    for (let i = 0; i < numeroElementi; i++) {
        res.push({
            x: currentX,
            y: 0,
            width: LARGHEZZA_GRIGLIA,
            height: ALTEZZA_GRIGLIA
        }); 

        currentX = currentX + interasse;
    }

    return res;
}