import React, { FC } from "react";
import { Group, Rect } from "react-konva";
import { SPESSORE_TELAIO_FINESTRA } from "../../../../../../../core/common_constants";

export const Finestra: FC<{x: number, y: number, width: number, height: number, spessoreTelaio: number, color: HTMLImageElement|undefined}> = (props) => {
    const {x, y, width, height, color, spessoreTelaio} = props;

    return (
        <Group x={x} y={y}>
            <Group x={0} y={-(height/2)}>
                <Rect
                    x={0}
                    y={0} 
                    width={width} 
                    height={height} 
                    stroke={"#000"} 
                    strokeWidth={0.75}
                    fillPatternImage={color}
                />
                <Rect
                    x={spessoreTelaio}
                    y={spessoreTelaio} 
                    width={width - (2*spessoreTelaio)} 
                    height={height - (2*spessoreTelaio)} 
                    stroke={"#000"} 
                    strokeWidth={0.75}
                    fill={"lightblue"}
                />
            </Group>
        </Group>
    );
}