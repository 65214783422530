import React, { FC, useEffect, useState } from "react";
import { IRect } from "konva/lib/types";
import { 
    DEFAULT_LARGHEZZA_GUARNIZIONE,
    PROFONDITA_INVOLUCRO, 
    SPESSORE_STIPITE, 
    SPESSORE_TELAIO_FINESTRA, 
    ALTEZZA_PANNELLO_PORTA_CLASSIC, 
    SPESSORE_TELAIO_GLASSPANEL
} from "../../../../../../../core/common_constants";
import { Group, Label, Line, Rect, Tag, Text } from "react-konva";
import { degree, distance, toDrawUnit } from "../../../../../../../core/common_functions";
import { Vector } from "vecti";
import { useStoreSelector } from "../../../../../../../../hooks/StoreHooks";
import { ColorCode, DoorType, InstallationType, ModelType, SpringType } from "../../../../../../../core/common_types";
import { Tubolare } from "../accessories/tubolare";
import { Color, Option, WindowSize } from "../../../../../../../models/door_configuration";
import { Guarnizione } from "../accessories/guarnizione";
import { PannelloRect } from "../../../../../../../core/common_draw_types";
import useImage from 'use-image';
import { calcolaFinestreDaNumeroElementi, calcolaGriglieDaNumeroElementi } from "../draw_utils";
import { Finestra } from "../accessories/finestra";
import { Griglia } from "../accessories/griglia";
import { SuperficieAreata } from "../accessories/superficie_areata";

export const PortaSezionaleClassic: FC<{
    pf: Vector, 
    lfdraw: number, 
    hfdraw: number, 
    lpdraw: number, 
    hpdraw: number, 
    modello: Option, 
    doorColor: HTMLImageElement|undefined, 
    windowsColor: HTMLImageElement|undefined, 
    gridsColor: HTMLImageElement|undefined
    onPanelClicked?: (panelData: PannelloRect) => void}> = (props) => {
    const {pf, lpdraw, hpdraw, lfdraw, hfdraw, modello, doorColor, windowsColor, gridsColor, onPanelClicked} = props;    
    const larghezzaGuarnizione = toDrawUnit(DEFAULT_LARGHEZZA_GUARNIZIONE);
    const {doorConfigurationBuilder} = useStoreSelector(store => store);
    
    const buildPannelli = (): PannelloRect[] => {
        let hDisp: number = 0;
        let wDisp: number = 0;
        let hp: number = 0;
        let ptBottomLeft = Vector.of([(lfdraw/2)-(lpdraw/2), hfdraw]);
        let ptTopLeft = Vector.of([(lfdraw/2)-(lpdraw/2), hfdraw-hpdraw]);
        let ptTopRight = Vector.of([(lfdraw/2)+(lpdraw/2), hfdraw-hpdraw]);
        if (!doorConfigurationBuilder.isOltreLuce()) {
            hDisp = ptBottomLeft.y - ptTopLeft.y;
            wDisp = ptTopRight.x - ptTopLeft.x;
            hp = ALTEZZA_PANNELLO_PORTA_CLASSIC * hDisp / hpdraw;            
        } else {
            let vecDirTopLeft = pf.subtract(Vector.of([0, 0])).normalize();
            let vecDirTopRight = pf.subtract(Vector.of([lfdraw, 0])).normalize();
            let vecDirBottomLeft = pf.subtract(Vector.of([0, hfdraw])).normalize();
            
            if (doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.oltre_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.in_luce) {                    
                ptTopLeft = Vector.of([lfdraw-lpdraw, hfdraw-hpdraw]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                ptBottomLeft = Vector.of([lfdraw-lpdraw, hfdraw]).add(vecDirBottomLeft.multiply(SPESSORE_STIPITE));
                ptTopRight = Vector.of([lfdraw-10, hfdraw-hpdraw]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));
            } else if (doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.oltre_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.in_luce) {
                ptTopLeft = Vector.of([10, hfdraw-hpdraw]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                ptBottomLeft = Vector.of([10, hfdraw]).add(vecDirBottomLeft.multiply(SPESSORE_STIPITE));
                ptTopRight = Vector.of([lfdraw-(lfdraw-lpdraw), hfdraw-hpdraw]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));
            } else {
                ptTopLeft = Vector.of([(lfdraw/2)-(lpdraw/2), hfdraw-hpdraw]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                ptBottomLeft = Vector.of([(lfdraw/2)-(lpdraw/2), hfdraw]).add(vecDirBottomLeft.multiply(SPESSORE_STIPITE));
                ptTopRight = Vector.of([(lfdraw/2)+(lpdraw/2), hfdraw-hpdraw]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));
            }
            
            hDisp = ptBottomLeft.y - ptTopLeft.y;
            wDisp = ptTopRight.x - ptTopLeft.x;
            hp = ALTEZZA_PANNELLO_PORTA_CLASSIC * hDisp / hpdraw;
        }

        let y: number = ptBottomLeft.y;
        let hCur: number = 0;
        let panelNumber = 1;
        let panelCount: number = 0;

        let panels: PannelloRect[] = [];
        while (hCur < hDisp) {
            let height = hp;
            let selectable: boolean = true;
            let isTagliato: boolean = false;

            if ((hCur + hp) > hDisp) {
                height = hDisp - hCur;
                selectable = false;
                isTagliato = true;
            }

            let p: PannelloRect = { 
                bbox: {x: ptTopLeft.x, y: y - height, width: wDisp, height},
                yCenter: y - (hp / 2),
                panelNumber,
                panelCount,
                selectable,
                isTagliato
            };

            panels.push(p);

            hCur += height;
            y -= height;
            panelNumber++;
        }

        panelCount = panels.length;
        panels.forEach(p => p.panelCount = panelCount);
        
        return panels;
    }

    const buildGuarnizioneSx = (): {x: number, y: number, length: number, rotation: number} => {
        if (!doorConfigurationBuilder.isOltreLuce()) {
            return {
                x: (lfdraw/2)-(lpdraw/2), 
                y: hfdraw - hpdraw, 
                length: hpdraw,
                rotation: 0
            };          
        } else {
            let vecDirTopLeft = pf.subtract(Vector.of([0, 0])).normalize();            
            let vecDirBottomLeft = pf.subtract(Vector.of([0, hfdraw])).normalize();
            
            let ptTopLeft: Vector;
            let ptBottomLeft: Vector;
            if (doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.oltre_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.in_luce) {
                ptTopLeft = Vector.of([0, hfdraw-hpdraw]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                ptBottomLeft = Vector.of([0, hfdraw]).add(vecDirBottomLeft.multiply(SPESSORE_STIPITE));
            } else if (doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.oltre_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.in_luce) {
                ptTopLeft = Vector.of([10, hfdraw-hpdraw]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                ptBottomLeft = Vector.of([10, hfdraw]).add(vecDirBottomLeft.multiply(SPESSORE_STIPITE));
            } else if (doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.oltre_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.oltre_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneTraversa == InstallationType.in_luce) {
                ptTopLeft = Vector.of([0, hfdraw-hpdraw]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                ptBottomLeft = Vector.of([0, hfdraw]).add(vecDirBottomLeft.multiply(SPESSORE_STIPITE));
            } else if (doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.in_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.in_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneTraversa == InstallationType.oltre_luce) {
                ptTopLeft = Vector.of([10, hfdraw-hpdraw]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                ptBottomLeft = Vector.of([10, hfdraw]).add(vecDirBottomLeft.multiply(SPESSORE_STIPITE));
            } else {
                ptTopLeft = Vector.of([0, hfdraw-hpdraw]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                ptBottomLeft = Vector.of([0, hfdraw]).add(vecDirBottomLeft.multiply(SPESSORE_STIPITE));
            }
            
            return {
                x: ptTopLeft.x, 
                y: ptTopLeft.y, 
                length: ptBottomLeft.y-ptTopLeft.y, 
                rotation: 0
            }; 
        }
    }

    const buildGuarnizioneDx = (): {x: number, y: number, length: number, rotation: number} => {
        if (!doorConfigurationBuilder.isOltreLuce()) {
            return {
                x: (lfdraw/2)+(lpdraw/2) - larghezzaGuarnizione,
                y: hfdraw - hpdraw,
                length: hpdraw,
                rotation: 0
            }
        } else {
            let vecDirTopRight = pf.subtract(Vector.of([lfdraw, 0])).normalize();
            let vecDirBottomRight = pf.subtract(Vector.of([lfdraw, hfdraw])).normalize();

            let ptTopRight: Vector;
            let ptBottomRight: Vector;
            if (doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.oltre_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.in_luce) {                    
                ptTopRight = Vector.of([lfdraw-larghezzaGuarnizione-10, hfdraw-hpdraw]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));
                ptBottomRight = Vector.of([lfdraw-larghezzaGuarnizione-10, hfdraw]).add(vecDirBottomRight.multiply(SPESSORE_STIPITE));
            } else if (doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.in_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.oltre_luce) {                    
                ptTopRight = Vector.of([lfdraw-larghezzaGuarnizione, hfdraw-hpdraw]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));
                ptBottomRight = Vector.of([lfdraw-larghezzaGuarnizione, hfdraw]).add(vecDirBottomRight.multiply(SPESSORE_STIPITE));
            } else if (doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.oltre_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.oltre_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneTraversa == InstallationType.in_luce) {
                ptTopRight = Vector.of([lfdraw-larghezzaGuarnizione, hfdraw-hpdraw]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));   
                ptBottomRight = Vector.of([lfdraw-larghezzaGuarnizione, hfdraw]).add(vecDirBottomRight.multiply(SPESSORE_STIPITE));             
            } else if (doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.in_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.in_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneTraversa == InstallationType.oltre_luce) {
                ptTopRight = Vector.of([lfdraw-10-larghezzaGuarnizione, 0]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));   
                ptBottomRight = Vector.of([lfdraw-10-larghezzaGuarnizione, hfdraw]).add(vecDirBottomRight.multiply(SPESSORE_STIPITE));
            } else {
                ptTopRight = Vector.of([lfdraw-larghezzaGuarnizione, hfdraw-hpdraw]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));
                ptBottomRight = Vector.of([lfdraw-larghezzaGuarnizione, hfdraw]).add(vecDirBottomRight.multiply(SPESSORE_STIPITE));
            }

            return {
                x: ptTopRight.x, 
                y: ptTopRight.y, 
                length: ptBottomRight.y-ptTopRight.y, 
                rotation: 0
            }; 
        }
    }

    const buildGuarnizioneTraversa = (): {x: number, y: number, length: number, rotation: number} => {
        if (!doorConfigurationBuilder.isOltreLuce()) {
            return {
                x: (lfdraw/2)+(lpdraw/2),
                y: hfdraw-hpdraw,
                length: lpdraw,
                rotation: 90
            }
        } else {
            let vecDirTopLeft = pf.subtract(Vector.of([0, 0])).normalize();
            let vecDirTopRight = pf.subtract(Vector.of([lfdraw, 0])).normalize();

            let ptTopLeft: Vector;
            let ptTopRight: Vector;
            if (doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.oltre_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.in_luce) {                               
                if (doorConfigurationBuilder.configuration?.tipoInstallazioneTraversa == InstallationType.in_luce) {
                    ptTopLeft = Vector.of([0, hfdraw-hpdraw]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));         
                    ptTopRight = Vector.of([lfdraw - 10, hfdraw-hpdraw]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));
                } else {
                    ptTopLeft = Vector.of([0, 0]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));         
                    ptTopRight = Vector.of([lfdraw - 10, 0]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));
                }
            } else if (doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.in_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.oltre_luce) {            
                if (doorConfigurationBuilder.configuration?.tipoInstallazioneTraversa == InstallationType.in_luce) {
                    ptTopLeft = Vector.of([10, hfdraw-hpdraw]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                    ptTopRight = Vector.of([lfdraw, hfdraw-hpdraw]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));                
                } else {
                    ptTopLeft = Vector.of([10, 0]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                    ptTopRight = Vector.of([lfdraw, 0]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));                
                }                        
            } else if (doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.oltre_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.oltre_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneTraversa == InstallationType.in_luce) {                    
                ptTopLeft = Vector.of([0, hfdraw-hpdraw]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                ptTopRight = Vector.of([lfdraw, hfdraw-hpdraw]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));                
            } else {
                if (doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.in_luce &&
                    doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.in_luce) {
                    ptTopLeft = Vector.of([10, 0]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                    ptTopRight = Vector.of([lfdraw - 10, 0]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));
                } else {
                    ptTopLeft = Vector.of([0, 0]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                    ptTopRight = Vector.of([lfdraw, 0]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));
                }
            }

            return {
                x: ptTopRight.x, 
                y: ptTopRight.y, 
                length: ptTopRight.x-ptTopLeft.x, 
                rotation: 90
            }; 
        }
    }

    const buildTubolareSx = (): {x: number, y: number, length: number, width: number, rotation: number} => {        
        if (!doorConfigurationBuilder.isOltreLuce()) {
            return {
                x: (lfdraw/2)-(lpdraw/2) - 10, 
                y: hfdraw - hpdraw, 
                length: hpdraw,
                width: 100,
                rotation: 0
            };
        } else {
            let vecDirTopLeft = pf.subtract(Vector.of([0, 0])).normalize();            
            let vecDirBottomLeft = pf.subtract(Vector.of([0, hfdraw])).normalize();

            let ptTopLeft: Vector;
            let ptBottomLeft: Vector;
            if (doorConfigurationBuilder.configuration?.tipoInstallazioneTraversa == InstallationType.in_luce) {
                ptTopLeft = Vector.of([0, hfdraw-hpdraw]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                ptBottomLeft = Vector.of([0, hfdraw]).add(vecDirBottomLeft.multiply(SPESSORE_STIPITE));
            } else {
                ptTopLeft = Vector.of([0, 0]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                ptBottomLeft = Vector.of([0, hfdraw]).add(vecDirBottomLeft.multiply(SPESSORE_STIPITE));
            }

            return {
                x: ptTopLeft.x,
                y: ptTopLeft.y,
                length: distance(ptTopLeft.x, ptTopLeft.y, ptBottomLeft.x, ptBottomLeft.y),
                width: 100,
                rotation: 0
            }; 
        }
    }

    const buildTubolareDx = (): {x: number, y: number, length: number, width: number, rotation: number} => {
        if (!doorConfigurationBuilder.isOltreLuce()) {
            return {
                x: (lfdraw/2)+(lpdraw/2),
                y: hfdraw - hpdraw,
                length: hpdraw,
                width: 100,
                rotation: 0
            }
        } else {
            let vecDirTopRight = pf.subtract(Vector.of([lfdraw, 0])).normalize();
            let vecDirBottomRight = pf.subtract(Vector.of([lfdraw, hfdraw])).normalize();

            let ptTopRight: Vector;
            let ptBottomRight: Vector
            if (doorConfigurationBuilder.configuration?.tipoInstallazioneTraversa == InstallationType.in_luce) {
                ptTopRight = Vector.of([lfdraw-10, hfdraw-hpdraw]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));
                ptBottomRight = Vector.of([lfdraw-10, hfdraw]).add(vecDirBottomRight.multiply(SPESSORE_STIPITE));
            } else {
                ptTopRight = Vector.of([lfdraw-10, 0]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));
                ptBottomRight = Vector.of([lfdraw-10, hfdraw]).add(vecDirBottomRight.multiply(SPESSORE_STIPITE));
            }

            return {
                x: ptTopRight.x,
                y: ptTopRight.y,
                length: distance(ptTopRight.x, ptTopRight.y, ptBottomRight.x, ptBottomRight.y),
                width: 100,
                rotation: 0
            }; 
        }
    }

    const buildTubolareTraversa = (sectionWidth:number, offset: number): {x: number, y: number, length: number, width: number, rotation: number} => {        
        if (!doorConfigurationBuilder.isOltreLuce()) {
            return {
                x: lfdraw-lpdraw - (2 * 10),
                y: hfdraw-hpdraw - offset,
                length: lpdraw + (2 * 10),
                width: sectionWidth,
                rotation: 270
            }
        } else {
            let vecDirTopLeft = pf.subtract(Vector.of([0, 0])).normalize();
            let vecDirTopRight = pf.subtract(Vector.of([lfdraw, 0])).normalize();

            let ptTopLeft: Vector;
            let ptTopRight: Vector;
            if (doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.oltre_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.in_luce) {
                ptTopLeft = Vector.of([0, hfdraw-hpdraw]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                ptTopRight = Vector.of([lfdraw, hfdraw-hpdraw]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));
            } else if (doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.oltre_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.in_luce) {
                ptTopLeft = Vector.of([0, hfdraw-hpdraw]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                ptTopRight = Vector.of([lfdraw, hfdraw-hpdraw]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));
            } else if (doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.oltre_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.oltre_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneTraversa == InstallationType.in_luce) {
                ptTopLeft = Vector.of([0, hfdraw-hpdraw]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                ptTopRight = Vector.of([lfdraw, hfdraw-hpdraw]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));
            } else {
                ptTopLeft = Vector.of([(lfdraw/2)-(lpdraw/2), hfdraw-hpdraw]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                ptTopRight = Vector.of([(lfdraw/2)+(lpdraw/2), hfdraw-hpdraw]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));
            }

            return {
                x: ptTopLeft.x,
                y: ptTopLeft.y - offset,
                length: distance(ptTopLeft.x, ptTopLeft.y, ptTopRight.x, ptTopRight.y),
                width: sectionWidth,
                rotation: 270
            }; 
        }
    }

    const renderTubolareVeletta = () => {
        if (!doorConfigurationBuilder.configuration) return null;
        
        if (doorConfigurationBuilder.configuration.tipoMolle == SpringType.torsione) {
            let sectionWidth: number = 100;
            let doppioTubolare: boolean = true;
            
            return (
                <>
                    <Tubolare color={doorColor} {...buildTubolareTraversa(sectionWidth, 0)} />
                    {doppioTubolare && <Tubolare color={doorColor} {...buildTubolareTraversa(sectionWidth, 10)} />}
                </>
            );
        } else if (doorConfigurationBuilder.configuration?.tipoMolle == SpringType.trazione) {
            let sectionWidth = 60;
            if (doorConfigurationBuilder.configuration?.isMotorizzata) {
                sectionWidth = 120;
            } 
            return <Tubolare color={doorColor} {...buildTubolareTraversa(sectionWidth, 0)} />
        }

        return null;
    }

    const renderPanels = () => {
        let pannelli = buildPannelli();
        
        return pannelli.map((p, idx, ar) => (
            <PannelloPortaClassic
                key={`panel_${(idx+1)}`}
                panelData={p}
                doorColor={doorColor}
                windowColor={windowsColor}
                gridColor={gridsColor}
                modello={modello}
                onClick={onPanelClicked}
            />
        ));
    }

    const renderLabelCampione = () => {
        let pt1: Vector = Vector.of([0, hpdraw]);
        let pt2: Vector = Vector.of([lpdraw, 0]);
        let rads = degree(pt1.x, pt1.y, pt2.x, pt2.y);
        return (
            <Label x={(lfdraw/2)-75} y={(hfdraw/2)+40} rotation={rads} opacity={0.6}>
                <Tag fill="white" stroke="red"/>
                <Text text={"Colore da campione"} padding={5} fontSize={20} />
            </Label>
        );
    }

    if (!doorConfigurationBuilder.configuration) return null;
    if (!doorConfigurationBuilder.configuration.porta) return null;
    if (!doorConfigurationBuilder.configuration.modello) return null;

    return (
        <>
            {renderPanels()}
            
            {doorConfigurationBuilder.isMontanteDxInLuce() && <Tubolare color={doorColor} {...buildTubolareSx()} />}
            {doorConfigurationBuilder.isMontanteSxInLuce() && <Tubolare color={doorColor} {...buildTubolareDx()} />}
            {doorConfigurationBuilder.isTraversaInLuce() && renderTubolareVeletta()}
            
            <Guarnizione {...buildGuarnizioneSx()} />
            <Guarnizione {...buildGuarnizioneDx()} />
            <Guarnizione {...buildGuarnizioneTraversa()} />

            {doorConfigurationBuilder.configuration.tipoColoreEsterno == "a_campione" && renderLabelCampione()}
        </>
    );
}

const PannelloPortaClassic: FC<{modello: Option, doorColor: HTMLImageElement|undefined, windowColor: HTMLImageElement|undefined, gridColor: HTMLImageElement|undefined, panelData: PannelloRect, onClick?: (panelData: PannelloRect) => void}> = (props) => {
    const {doorConfigurationBuilder} = useStoreSelector(store => store);
    const delta = toDrawUnit(30/2);

    const addFinituraWide = () => {
        let ptTopLeft = Vector.of([props.panelData.bbox.x, props.panelData.bbox.y + props.panelData.bbox.height + delta]);
        let ptTopRight = Vector.of([props.panelData.bbox.x + props.panelData.bbox.width, props.panelData.bbox.y + props.panelData.bbox.height + delta]);
        let ptBottomLeft = Vector.of([props.panelData.bbox.x, props.panelData.bbox.y + props.panelData.bbox.height - delta]);
        let ptBottomRight = Vector.of([props.panelData.bbox.x + props.panelData.bbox.width, props.panelData.bbox.y + props.panelData.bbox.height - delta]);
        let ptCenterLeft = Vector.of([props.panelData.bbox.x, props.panelData.yCenter]);
        let ptCenterRight = Vector.of([props.panelData.bbox.x + props.panelData.bbox.width, props.panelData.yCenter]);

        return (
            <Group>
                {props.panelData.panelNumber == 1 && <Line 
                    points={[ptTopLeft.x, props.panelData.bbox.y + props.panelData.bbox.height, ptTopRight.x, props.panelData.bbox.y + props.panelData.bbox.height]}
                    stroke={"#000"}
                    strokeWidth={0.5}
                />}
                {props.panelData.panelNumber > 1 && <Line 
                    points={[ptTopLeft.x, ptTopLeft.y, ptTopRight.x, ptTopRight.y]}
                    stroke={"#000"}
                    strokeWidth={0.5}
                />}
                {props.panelData.panelNumber != props.panelData.panelCount && <Line 
                    points={[ptCenterLeft.x, ptCenterLeft.y - delta, ptCenterRight.x, ptCenterRight.y - delta]}
                    stroke={"#000"}
                    strokeWidth={0.5}                    
                />}
                {props.panelData.panelNumber != props.panelData.panelCount && <Line 
                    points={[ptCenterLeft.x, ptCenterLeft.y + delta, ptCenterRight.x, ptCenterRight.y + delta]}
                    stroke={"#000"}
                    strokeWidth={0.5}
                />}
                <Line 
                    points={[ptBottomLeft.x, ptBottomLeft.y, ptBottomRight.x, ptBottomRight.y]}
                    stroke={"#000"}
                    strokeWidth={0.5}
                />
            </Group>
        );
    }

    const renderFinestratura = () => {
        if (!doorConfigurationBuilder.configuration) return null;
        if (!doorConfigurationBuilder.configuration.finestratura) return null;
        if (doorConfigurationBuilder.configuration.finestratura.windowSize == WindowSize.undefined) return null;

        let finestratura = doorConfigurationBuilder.configuration.finestratura.positioning.find(f => f.panelNumber == props.panelData.panelNumber);
        
        if (!finestratura) return null;

        let fRender: IRect[] = [];

        let glasspanel = doorConfigurationBuilder.isGlasspanel();
        let spessoreTelaio = SPESSORE_TELAIO_FINESTRA;
        if (!glasspanel) {
            fRender = calcolaFinestreDaNumeroElementi(finestratura.elementsNumber, props.panelData.bbox.width, doorConfigurationBuilder.configuration.finestratura.windowSize);
        } else {
            spessoreTelaio = SPESSORE_TELAIO_GLASSPANEL;
            fRender.push({
                x: 0,
                y: 0,
                width: props.panelData.bbox.width,
                height: props.panelData.bbox.height
            });
        }
        
        return (
            <Group x={props.panelData.bbox.x} y={props.panelData.yCenter}>
                {fRender.map((f, idx) => <Finestra
                    key={"window_" + (idx+1)}
                    color={props.windowColor}
                    height={f.height}
                    width={f.width}
                    x={f.x}
                    y={0}
                    spessoreTelaio={spessoreTelaio}
                />)}  
            </Group>
        );
    }

    const renderVentilazione = () => {
        if (!doorConfigurationBuilder.configuration) return null;
        if (!doorConfigurationBuilder.configuration.ventilazione) return null;

        let ventilazione = doorConfigurationBuilder.configuration.ventilazione.positioning.find(f => f.panelNumber == props.panelData.panelNumber);
        
        if (!ventilazione) return null;
        
        let superficieAreata = doorConfigurationBuilder.isSuperficieAreata();
        if (superficieAreata) {
            return (
                <Group x={props.panelData.bbox.x} y={props.panelData.bbox.y}>
                    <SuperficieAreata x={0} y={0} width={props.panelData.bbox.width} height={props.panelData.bbox.height} color={props.gridColor} />
                </Group>
            );
        }

        let fRender: IRect[] = calcolaGriglieDaNumeroElementi(ventilazione.elementsNumber, props.panelData.bbox.width);
        
        return (
            <Group x={props.panelData.bbox.x} y={props.panelData.yCenter}>
                {fRender.map((f, idx) => <Griglia
                    key={"grid_" + (idx+1)}
                    height={f.height}
                    width={f.width}
                    x={f.x}
                    y={0}
                    color={props.gridColor}
                />)}  
            </Group>
        );
    }

    const onTouch = () => {
        if (!props.panelData.selectable) return;

        props.onClick?.(props.panelData);
    }

    return (
        <Group>
            <Rect            
                x={props.panelData.bbox.x}
                y={props.panelData.bbox.y} 
                width={props.panelData.bbox.width} 
                height={props.panelData.bbox.height} 
                stroke={"#000"} 
                strokeWidth={0.5}
                strokeEnabled={props.modello?.name != ModelType.wide}
                fillPatternImage={props.doorColor ? props.doorColor : undefined}
                onTap={() => onTouch()}
                onClick={() => onTouch()}
            />

            {props.modello?.name == ModelType.wide && addFinituraWide()}

            {renderFinestratura()}
            {renderVentilazione()}
        </Group>
    );
}