import Konva from "konva";
import React, { FC, useEffect, useRef } from "react";
import { Group, Label, Rect, Tag, Text } from "react-konva";

export const SuperficieAreata: FC<{x: number, y: number, width: number, height: number, color: HTMLImageElement|null|undefined}> = (props) => {
    const {x, y, width, height} = props;
    const labelRef = useRef<Konva.Label>(null);

    useEffect(() => {
        if (!labelRef.current) return;

        let lw = labelRef.current.getWidth();
        let lh = labelRef.current.getHeight();
        
        let y = (height/2)-(lh/2);
        let x = (width/2)-(lw/2);
        labelRef.current.setPosition({x, y});
    }, []);

    return (
        <Group>
            <Rect
                x={x}
                y={y}
                width={width}
                height={height}
                fill={"#f1f1f1"}
                opacity={0.3}
            />
            <Label ref={labelRef}>
                <Tag strokeWidth={0.75} />
                <Text padding={5} text={"SUPERFICIE AREATA\nIMMAGINE IN ALLESTIMENTO"} align="center" fontSize={10} />
            </Label>
        </Group>
    );
}