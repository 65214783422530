import { Checkbox, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { FinishType, NullableNumber, NullableString } from "../../../../../../core/common_types";
import { useDoorConfigurationBuilderActions } from "../../../../../../../store/reducers/door_configuration_builder";
import { useStoreDispatch, useStoreSelector } from "../../../../../../../hooks/StoreHooks";
import { numberCompare } from "../../../../../../core/common_functions";
import { Color, ColorSide, ColorType, FrameInternalColorType, Option } from "../../../../../../models/door_configuration";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { ColorsProps } from "../../../../../../ui/dialogs/colors_dialog";
import { useDialogs } from "../../../../../../providers/dialogs_provider";
import { useFormik } from "formik";
import finishes from "../../../../../../assets/finishes.json";
import rals from "../../../../../../assets/rals.json";
import { useFinishes } from "../../../../../../providers/finish_provider";

type FormModelloFinituraModel = {
    porta: NullableNumber,
    modello: NullableNumber,
    finitura: NullableNumber,
    finituraVetro: NullableNumber,
    tipoColoreEsterno: ColorType,
    coloreEsterno: NullableNumber,
    tipoColoreInterno: ColorType,
    coloreInterno: NullableNumber,
}

export const FormModelloFinitura: FC<{ options: Option[], colors: Color[] }> = ({ options, colors }) => {
    const { doorConfigurationBuilder } = useStoreSelector(store => store);
    const finishesProvider = useFinishes();
    const dialogs = useDialogs();
    const dispatcher = useStoreDispatch();
    const {
        setPorta,
        setModello,
        setFinitura,
        setFinituraVetro,
        setTipoColoreEsterno,
        setColoreEsterno,
        setColoreEsternoNsRal,        
        setTipoColoreInterno,
        setColoreInterno,
        setColoreInternoNsRal,
        setColoreTelaioInternoDiverso
    } = useDoorConfigurationBuilderActions();

    const formik = useFormik<{coloreEsternoNsRal: NullableString, coloreInternoNsRal: NullableString, usaColoreTelaioEsterno: boolean}>({
        initialValues: {
            coloreEsternoNsRal: doorConfigurationBuilder.configuration ? doorConfigurationBuilder.configuration.coloreEsternoNsRal : undefined,
            coloreInternoNsRal: doorConfigurationBuilder.configuration ? doorConfigurationBuilder.configuration.coloreInternoNsRal : undefined,
            usaColoreTelaioEsterno: doorConfigurationBuilder.configuration ? !doorConfigurationBuilder.configuration.coloreTelaioInternoDiverso : true,
        },
        onSubmit: (model) => {
            
        }
    });

    const getOptions = (type: string, parentId?: NullableNumber) => {
        let ret = options
            .filter(o => o && o.type == type && (!parentId || o.parentId == parentId))
            .sort((a, b) => (!a || !b) ? 0 : a.name.localeCompare(b.name));

        return ret;
    }

    const findOption = (optionId: NullableNumber, optionType: string, parentId: NullableNumber) => {
        let option = options.find(o => o?.optionId == optionId && o?.type == optionType && (!parentId || o?.parentId == parentId));
        return option ? option.id : undefined;
    }

    const getColors = (side: ColorSide, finitura: NullableNumber) => {
        if (!finitura) return [];

        let ret = colors
            .filter(c => c && c.parentOptionId == finitura && c.side == side)
            .sort((a, b) => (!a || !b) ? 0 : numberCompare(a.sortOrder, b.sortOrder))

        return ret;
    }

    const getRalName = (colorId: NullableNumber) => {
        if (!colorId) return "";

        let color = colors.find(c => c?.id == colorId);
        return color ? color.name + ` (${color.code})` : undefined;
    }

    const renderFinishPreview = (colorId: NullableNumber) => {
        if (!colorId) return null;
        
        let color = colors.find(c => c?.id == colorId);
        if (!color) return null;

        let preview = finishes.find(f => f.code == color?.code)?.img;
        if (preview) return <img src={preview} style={{width: "2rem", height: "1.75rem"}} />;

        preview = rals.find(f => f.RAL == color?.code)?.HEX;
        if (preview) return <div style={{width: "1.75rem", height: "1.75rem", backgroundColor: preview}}></div>;

        return null;        
    }

    const canChangeExternalColorType = (): boolean => {
        if (!model.finitura) return false;
        let finish = options.find(o => o?.id == model.finitura);
        if (!finish) return false;

        return finish.name != FinishType.special && finish.name != FinishType.material_hpl && finish.name != FinishType.wood_hpl && finish.name != FinishType.pietravera && finish.name != FinishType.woodstyle;
    }

    const canShowInternalColor = (): boolean => {
        if (!model.finitura) return false;
        let finish = options.find(o => o?.id == model.finitura);
        if (!finish) return false;

        return (finish.name != FinishType.glasspanel && finish.name != FinishType.full_vision) || !formik.values.usaColoreTelaioEsterno;
    }

    const isFullglass = (): boolean => {
        if (!model.finitura) return false;
        let finish = options.find(o => o?.id == model.finitura);
        if (!finish) return false;

        return finish.name == FinishType.glasspanel;
    }

    const isFullVision = (): boolean => {        
        if (!model.finitura) return false;
        let finish = options.find(o => o?.id == model.finitura);
        if (!finish) return false;

        return finish.name == FinishType.full_vision;
    }

    const [model, setModel] = useState<FormModelloFinituraModel>({
        porta: undefined,//findOption(doorConfigurationBuilder.configuration?.porta, "PORTA"),
        modello: undefined,//findOption(doorConfigurationBuilder.configuration?.modello, "MODELLO"),
        finitura: undefined,//findOption(doorConfigurationBuilder.configuration?.finitura, "FINITURA"),
        finituraVetro: undefined,
        tipoColoreEsterno: "ral_standard",
        coloreEsterno: undefined,
        tipoColoreInterno: "ral_standard",
        coloreInterno: undefined
    });

    const renderSelectModello = () => {
        let modelli = getOptions("MODELLO", model.porta);

        if (modelli.length == 1 && !model.modello) {
            dispatcher(setModello(modelli[0]?.optionId));
            setModel({...model, modello: modelli[0]?.id});
            return null;
        }

        if (modelli.length == 1 && model.modello) return null;

        return (
            <FormControl fullWidth className="mt-3">
                <InputLabel id="modello">Modello</InputLabel>
                <Select
                    labelId="modello"
                    value={model.modello ?? ""}
                    label="Modello"
                    onChange={(e) => {
                        let option = options.find(o => o?.id == Number(e.target.value));
                        if (option) {
                            setModel({
                                ...model,
                                modello: option.id,
                                finitura: undefined,
                                tipoColoreEsterno: "ral_standard",
                                coloreEsterno: undefined,
                                tipoColoreInterno: "ral_standard",
                                coloreInterno: undefined,
                                finituraVetro: undefined
                            });
                            formik.setValues({...formik.values, coloreEsternoNsRal: undefined, coloreInternoNsRal: undefined });
                            dispatcher(setModello(option.optionId));
                        }
                    }}>
                    {modelli.map(o => <MenuItem key={o?.id} value={o?.id}>{o?.name}</MenuItem>)}
                </Select>
            </FormControl>
        );
    }

    const renderSelectFinitura = () => {
        let finiture = getOptions("FINITURA", model.modello);

        if (finiture.length == 1 && !model.finitura) {            
            dispatcher(setFinitura(finiture[0]?.optionId));
            //setModel({ ...model, finitura: finiture[0]?.id });
            setDefaultColors(finiture[0]?.id);
            return null;
        }

        if (finiture.length == 1 && model.finitura) return null;

        return (
            <FormControl fullWidth className="mt-3">
                <InputLabel id="finitura">Finitura</InputLabel>
                <Select
                    labelId="finitura"
                    value={model.finitura ?? ""}
                    label="Finitura"
                    onChange={(e) => {
                        let option = options.find(o => o?.id == Number(e.target.value));
                        if (option) {
                            formik.setValues({...formik.values, coloreEsternoNsRal: undefined, coloreInternoNsRal: undefined });
                            dispatcher(setFinitura(option.optionId));

                            setDefaultColors(option.id);
                        }
                    }}>
                    {finiture.map(o => <MenuItem key={o?.id} value={o?.id}>{o?.name}</MenuItem>)}
                </Select>
            </FormControl>
        );
    }

    const renderSelectVetratura = () => {
        let vetrature = getOptions("VETRATURA", model.finitura);

        if (vetrature.length == 1 && !model.finituraVetro) {            
            dispatcher(setFinituraVetro(vetrature[0]?.optionId));
            setModel({ ...model, finituraVetro: vetrature[0]?.id });
            //setDefaultColors(finiture[0]?.id);
            return null;
        }

        if (vetrature.length == 1 && model.finituraVetro) return null;

        return (
            <FormControl fullWidth className="mt-3">
                <InputLabel id="glass_finish">Finitura vetro</InputLabel>
                <Select
                    labelId="glass_finish"
                    value={model.finituraVetro ?? ""}
                    label="Finitura vetro"
                    onChange={(e) => {
                        let option = options.find(o => o?.id == Number(e.target.value));
                        if (option) {
                            dispatcher(setFinituraVetro(option.optionId));
                            setModel({ ...model, finituraVetro: option.id });
                        }
                    }}>
                    {vetrature.map(o => <MenuItem key={o?.id} value={o?.id}>{o?.name}</MenuItem>)}
                </Select>
            </FormControl>
        );
    }

    const setDefaultColors = (finishId: NullableNumber)  => {
        let colors = getColors("external", finishId);
        let externalColorId = colors.length == 1 ? colors[0]?.id : null;

        colors = getColors("internal", finishId);
        let internalColorId = colors.length == 1 ? colors[0]?.id : null;
        
        if (externalColorId) {
            dispatcher(setTipoColoreEsterno("ral_standard"));
            dispatcher(setColoreEsterno(externalColorId));
        }
        if (internalColorId) {
            dispatcher(setTipoColoreInterno("ral_standard"));
            dispatcher(setColoreInterno(internalColorId));
        }

        setModel({ ...model, finitura: finishId, tipoColoreEsterno: "ral_standard", coloreEsterno: externalColorId, tipoColoreInterno: "ral_standard", coloreInterno: internalColorId });
    }

    useEffect(() => {
        if (doorConfigurationBuilder.configuration) {
            let porta = findOption(doorConfigurationBuilder.configuration.porta, "PORTA", null);
            let modello = porta ? findOption(doorConfigurationBuilder.configuration.modello, "MODELLO", porta) : undefined;
            let finitura = modello ? findOption(doorConfigurationBuilder.configuration.finitura, "FINITURA", modello) : undefined;
            let finituraVetro = finitura ? findOption(doorConfigurationBuilder.configuration.finituraVetro, "VETRATURA", finitura) : undefined;

            let tipoColoreEsterno = doorConfigurationBuilder.configuration.tipoColoreEsterno ?? "ral_standard";
            let coloreEsterno = doorConfigurationBuilder.configuration.coloreEsterno ?? undefined;
            let tipoColoreInterno = doorConfigurationBuilder.configuration.tipoColoreInterno ?? "ral_standard";
            let coloreInterno = doorConfigurationBuilder.configuration.coloreInterno ?? undefined;

            setModel({...model, porta, modello, finitura, tipoColoreEsterno, coloreEsterno, tipoColoreInterno, coloreInterno, finituraVetro});
        }
    }, []);

    return (
        <div>
            <h5 className="text-primary">Modello e finitura</h5>

            <FormControl fullWidth className="mt-2">
                <InputLabel id="porta">Gamma</InputLabel>
                <Select
                    labelId="porta"
                    value={model.porta ?? ""}
                    label="Gamma"
                    onChange={(e) => {
                        let option = options.find(o => o?.id == Number(e.target.value));
                        if (option) {                            
                            setModel({ 
                                ...model, 
                                porta: option.id, 
                                modello: undefined, 
                                finitura: undefined, 
                                tipoColoreEsterno: "ral_standard", 
                                coloreEsterno: undefined, 
                                tipoColoreInterno: "ral_standard", 
                                coloreInterno: undefined,
                                finituraVetro: undefined
                            });
                            formik.setValues({...formik.values, coloreEsternoNsRal: undefined, coloreInternoNsRal: undefined});
                            dispatcher(setPorta(option.optionId));
                        }
                    }}>
                    {getOptions("PORTA").map(o => <MenuItem key={o?.id} value={o?.id}>{o?.name}</MenuItem>)}
                </Select>
            </FormControl>

            {model.porta && renderSelectModello()}

            {model.modello && renderSelectFinitura()}

            {model.finitura && canChangeExternalColorType() && <FormControl fullWidth className="mt-3">
                <InputLabel id="tipo_colore_esterno">{isFullglass() || isFullVision() ? "Colore telaio" : "Colore esterno"}</InputLabel>
                <Select
                    labelId="tipo_colore_esterno"
                    value={model.tipoColoreEsterno ?? ""}
                    label={isFullglass() || isFullVision() ? "Colore telaio" : "Colore esterno"}
                    onChange={(e) => {
                        setModel({ 
                            ...model, 
                            tipoColoreEsterno: e.target.value as ColorType,
                            coloreEsterno: null,                                                        
                        });
                        formik.setFieldValue("coloreEsternoNsRal", undefined);
                        dispatcher(setTipoColoreEsterno(e.target.value as ColorType));
                    }}>
                    <MenuItem value={"ral_standard"}>RAL Standard</MenuItem>
                    <MenuItem value={"ral_non_standard"}>RAL NON Standard</MenuItem>
                    <MenuItem value={"a_campione"}>A Campione</MenuItem>
                </Select>
            </FormControl>}

            {model.finitura && model.tipoColoreEsterno == "ral_standard" && <TextField
                fullWidth 
                focused={false}
                className="mt-1"    
                label={!model.coloreEsterno ? "Seleziona il colore" : ""}
                name={"coloreEsterno"}
                value={getRalName(model.coloreEsterno)}
                InputProps={{
                    readOnly: true, 
                    startAdornment: model.coloreEsterno && (
                        <InputAdornment position="start">
                            {renderFinishPreview(model.coloreEsterno)}
                        </InputAdornment>
                    ),

                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={() => dialogs?.selectColor({colors: getColors("external", model.finitura), selectedColor: model.coloreEsterno, onConfirm: selectedColor => {
                                if (selectedColor) {
                                    setModel({ ...model, coloreEsterno: selectedColor });
                                    dispatcher(setColoreEsterno(selectedColor));
                                } else {
                                    setModel({ ...model, coloreEsterno: null });
                                    dispatcher(setColoreEsterno(null));
                                }
                            }})}>
                                <OpenInNewIcon style={{ color: "black" }} />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />}

            {model.finitura && model.tipoColoreEsterno == "ral_non_standard" && <TextField
                fullWidth 
                focused={false}
                className="mt-1"
                label={!formik.values.coloreEsternoNsRal ? "Seleziona il colore" : ""}
                name={"coloreEsterno"}
                value={finishesProvider?.getRalName(formik.values.coloreEsternoNsRal)}
                InputProps={{
                    readOnly: true, 
                    startAdornment: formik.values.coloreEsternoNsRal && (
                        <InputAdornment position="start">
                            {finishesProvider?.getRalPreview(formik.values.coloreEsternoNsRal)}
                        </InputAdornment>
                    ),

                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={() => dialogs?.selectRalColor({onConfirm: selectedColor => {                                
                                formik.setFieldValue("coloreEsternoNsRal", selectedColor)
                                dispatcher(setColoreEsternoNsRal(selectedColor));
                            }})}>
                                <OpenInNewIcon style={{ color: "black" }} />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />}

            {model.finitura && (isFullglass() || isFullVision()) && <FormControlLabel 
                className="w-full mt-2" 
                control={
                    <Checkbox
                        checked={formik.values.usaColoreTelaioEsterno}
                        onChange={(e) => {
                            formik.setFieldValue("usaColoreTelaioEsterno", e.target.checked);
                            dispatcher(setColoreTelaioInternoDiverso(!e.target.checked));
                        }}
                        sx={{marginTop: "0", marginBottom: "0"}}
                    />
                } 
                label="Verniciatura interna telaio come lato esterno" 
                sx={{marginTop: "0", marginBottom: "0"}}
            />}

            {model.finitura && canShowInternalColor() && <FormControl fullWidth className="mt-3">
                <InputLabel id="tipo_colore_interno">{isFullglass() || isFullVision() ? "Colore telaio interno" : "Colore interno"}</InputLabel>
                <Select
                    labelId="tipo_colore_interno"
                    value={model.tipoColoreInterno ?? ""}
                    label={isFullglass() || isFullVision() ? "Colore telaio interno" : "Colore interno"}
                    onChange={(e) => {
                        setModel({ 
                            ...model, 
                            tipoColoreInterno: e.target.value as ColorType,
                            coloreInterno: null
                        });                        
                        formik.setFieldValue("coloreInternoNsRal", undefined);
                        dispatcher(setTipoColoreInterno(e.target.value as ColorType));
                    }}>
                    <MenuItem value={"ral_standard"}>RAL Standard</MenuItem>
                    <MenuItem value={"ral_non_standard"}>RAL NON Standard</MenuItem>
                    <MenuItem value={"a_campione"}>A Campione</MenuItem>
                </Select>
            </FormControl>}

            {model.finitura && canShowInternalColor() && model.tipoColoreInterno == "ral_standard" && <TextField
                fullWidth 
                focused={false}
                className="mt-1"
                label={!model.coloreInterno ? "Seleziona colore" : ""}
                name={"coloreInterno"}
                value={getRalName(model.coloreInterno)}
                InputProps={{
                    readOnly: true, 
                    startAdornment: model.coloreInterno && (
                        <InputAdornment position="start">
                            {renderFinishPreview(model.coloreInterno)}
                        </InputAdornment>
                    ),

                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={() => dialogs?.selectColor({colors: getColors(isFullglass() || isFullVision() ? "external" : "internal", model.finitura).filter(c => c?.id != model.coloreEsterno), selectedColor: model.coloreInterno, onConfirm: selectedColor => {
                                if (selectedColor) {
                                    setModel({ ...model, coloreInterno: selectedColor });
                                    dispatcher(setColoreInterno(selectedColor));
                                } else {
                                    setModel({ ...model, coloreInterno: null });
                                    dispatcher(setColoreInterno(null));
                                }
                            }})}>
                                <OpenInNewIcon style={{ color: "black" }} />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />}

            {model.finitura && canShowInternalColor() && model.tipoColoreInterno == "ral_non_standard" && <TextField
                fullWidth 
                focused={false}
                className="mt-1"
                label={!formik.values.coloreInternoNsRal ? "Colore interno" : ""}
                name={"coloreInterno"}
                value={finishesProvider?.getRalName(formik.values.coloreInternoNsRal)}
                InputProps={{
                    readOnly: true, 
                    startAdornment: formik.values.coloreInternoNsRal && (
                        <InputAdornment position="start">
                            {finishesProvider?.getRalPreview(formik.values.coloreInternoNsRal)}
                        </InputAdornment>
                    ),

                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={() => dialogs?.selectRalColor({onConfirm: selectedColor => {
                                formik.setFieldValue("coloreInternoNsRal", selectedColor)
                                dispatcher(setColoreInternoNsRal(selectedColor));
                            }})}>
                                <OpenInNewIcon style={{ color: "black" }} />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />}

            {model.finitura && (isFullglass() || isFullVision()) && renderSelectVetratura()}
        </div>
    );
}