import { Button, FormControl, IconButton, InputAdornment, InputLabel, ListItemText, MenuItem, Select, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useNavigate, useParams } from "react-router-dom";
import { useStoreDispatch, useStoreSelector } from "../../../../../hooks/StoreHooks";
import { getCurrentUser, isStepVisible, toDrawUnit } from "../../../../core/common_functions";
import { useDoorConfigurationBuilderActions } from "../../../../../store/reducers/door_configuration_builder";
import { useDialogs } from "../../../../providers/dialogs_provider";
import { useConfigurationsService } from "../../../../services/configurations_service";
import { Scene, SceneViewLabel } from "./components/object-draw/scene";
import { MetricsSummary } from "./components/panels/pnl_metrics_summary";
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { DoorDetails } from "./components/object-draw/environment/door_details";
import { Color, WindowingConfiguration, WindowSize, GlassType, ColorType, ColorSide } from "../../../../models/door_configuration";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { PannelloRect } from "../../../../core/common_draw_types";
import { calcolaNumeroFinestreDaLarghezzaPorta } from "./components/object-draw/draw_utils";
import { ColorCode, DoorType, FinishType, NullableNumber, NullableString } from "../../../../core/common_types";
import { useFormik } from "formik";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import finishes from "../../../../assets/finishes.json";
import rals from "../../../../assets/rals.json";
import { loadEnvFile } from "process";
import { useFinishes } from "../../../../providers/finish_provider";
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import { Label, Tag, Text } from "react-konva";

export const WindowingPage = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { base, doorConfigurationBuilder } = useStoreSelector(store => store);
    const dispatcher = useStoreDispatch();
    const currentUser = getCurrentUser(base);
    const { setStepActive, setStepComplete, setFinestratura, resetFinestratura } = useDoorConfigurationBuilderActions();
    const dialogs = useDialogs();
    const [isSubmit, setSubmit] = useState<boolean>(false);
    const configurationService = useConfigurationsService();
    const sceneContainerRef = useRef<HTMLDivElement>(null);
    const finishesProvider = useFinishes();

    const formik = useFormik<WindowingConfiguration>({
        initialValues: {
            positioning: doorConfigurationBuilder.configuration?.finestratura?.positioning ?? [],
            windowSize: doorConfigurationBuilder.configuration?.finestratura?.windowSize ?? WindowSize.undefined,
            quantity: doorConfigurationBuilder.configuration?.finestratura?.quantity ?? undefined,
            externalFrameColorType: doorConfigurationBuilder.configuration?.finestratura?.externalFrameColorType ?? "ral_standard",
            externalFrameColorCode: doorConfigurationBuilder.configuration?.finestratura?.externalFrameColorCode ?? undefined,
            internalFrameColorType: doorConfigurationBuilder.configuration?.finestratura?.internalFrameColorType ?? "ral_standard",
            internalFrameColorCode: doorConfigurationBuilder.configuration?.finestratura?.internalFrameColorCode ?? undefined,
            glassFinish: doorConfigurationBuilder.configuration?.finestratura?.glassFinish ?? undefined,
        },
        onSubmit: (data) => {

        }
    });

    const submit = async () => {
        setSubmit(true);
        try {
            if (!doorConfigurationBuilder.configuration) throw "invalid configuration";
            if (doorConfigurationBuilder.configuration.finestratura) {
                if ((doorConfigurationBuilder.configuration.finestratura.quantity ?? 0) <= 0) throw { severity: "warning", message: "Attenzione, selezionare la quantità" };
                if (!doorConfigurationBuilder.configuration.finestratura.externalFrameColorCode) throw { severity: "warning", message: "Attenzione, inserisci il colore RAL del telaio" };
                if (!doorConfigurationBuilder.configuration.finestratura.positioning || doorConfigurationBuilder.configuration.finestratura.positioning.length <= 0) throw { severity: "warning", message: "Attenzione, posiziona le finestre prima di procedere" };
            }

            if (doorConfigurationBuilder.configuration.finestratura) {
                await configurationService.setFinestratura(currentUser, doorConfigurationBuilder.configuration?.configurationId, doorConfigurationBuilder.configuration.finestratura);
            } else {
                await configurationService.removeFinestratura(currentUser, doorConfigurationBuilder.configuration?.configurationId);
            }

            setSubmit(false);

            dispatcher(setStepComplete({ stepSlug: "finestratura", isComplete: true }));

            navigate(`/door-configurations/${id}/ventilazione`);
        } catch (e: any) {
            setSubmit(false);

            if (typeof e == "string") {
                dialogs?.showError("Attenzione...", e);
            } else {
                if (e.severity == "error") {
                    dialogs?.showError("Attenzione...", e.message);
                } else if (e.severity == "warning") {
                    dialogs?.showWarning("Attenzione...", e.message);
                }
            }
        }
    }

    const getRalName = (colorCode: NullableString) => {
        if (!colorCode) return "";

        let color = doorConfigurationBuilder.colors.find(c => c?.code == colorCode);        
        return color ? `${color.name} (${color.code})` : finishesProvider?.getRalName(colorCode);
    }

    const renderFinishPreview = (colorCode: NullableString) => {
        if (!colorCode) return null;
        
        let preview = finishes.find(f => f.code == colorCode)?.img;
        if (preview) return <img src={preview} style={{width: "2rem", height: "1.75rem"}} />;

        preview = rals.find(f => f.RAL == colorCode)?.HEX;
        if (preview) return <div style={{width: "1.75rem", height: "1.75rem", backgroundColor: preview}}></div>;

        return null;        
    }

    const isGlasspanel = (): boolean => {
        return formik.values.windowSize == WindowSize.glasspanel_vetro_4_12_4
        || formik.values.windowSize == WindowSize.glasspanel_vetro_5
        || formik.values.windowSize == WindowSize.glasspanel_vetro_6_7;
    }

    const renderSelectQuantita = () => {
        if (!doorConfigurationBuilder.configuration) return null;

        let startFrom: number = 1;
        let itemsNumber: number = 1;
        if (!isGlasspanel()) {
            itemsNumber = calcolaNumeroFinestreDaLarghezzaPorta(doorConfigurationBuilder.configuration.larghezzaPorta);
            startFrom = 2;
        }

        let quantities = [];
        for (let q = startFrom; q <= itemsNumber; q++) {
            quantities.push(q);
        }

        if (quantities.length < 2) return null;

        return (
            <FormControl fullWidth className="mt-3">
                <InputLabel id="numero_finestre">Numero finestre per pannello</InputLabel>
                <Select
                    labelId="numero_finestre"
                    value={formik.values.quantity ?? startFrom}
                    label="Numero finestre per pannello"
                    onChange={(e) => {
                        let values: WindowingConfiguration = {
                            ...formik.values,
                            quantity: e.target.value as number,
                            positioning: []
                        };
                        formik.setValues(values);
                        dispatcher(setFinestratura(values));
                    }}>
                    {quantities.map(q => <MenuItem value={q}>{q}</MenuItem>)}
                </Select>
            </FormControl>
        );
    }

    const renderSelectExternalFrameColorType = () => {
        if (!doorConfigurationBuilder.configuration) return null;

        const lbl = formik.values.windowSize != WindowSize._230x230 ? "Colore telaio esterno" : "Colore telaio esterno ed interno";

        return (
            <FormControl fullWidth className="mt-3">
                <InputLabel id="colore_telaio_esterno">{lbl}</InputLabel>
                <Select
                    labelId="colore_telaio_esterno"
                    value={getColorTypeValue("external")}
                    label={lbl}
                    onChange={(e) => {
                        let value = e.target.value;
                        let externalFrameColorType: ColorType = "ral_standard";
                        let externalFrameColorCode: NullableString = undefined;
                        if (isStandardRal(value ?? "")) {
                            externalFrameColorType = "ral_standard";
                            externalFrameColorCode = value;
                        } else {
                            externalFrameColorType = "ral_non_standard";
                            if (value != "ral_non_standard") {
                                externalFrameColorCode = value;
                            }
                        }

                        let values: WindowingConfiguration = {
                            ...formik.values,
                            externalFrameColorType,
                            externalFrameColorCode
                        };

                        if (values.windowSize == WindowSize._230x230) {
                            values.internalFrameColorType = externalFrameColorType;
                            values.internalFrameColorCode = externalFrameColorCode;
                        }

                        dispatcher(setFinestratura(values));
                        formik.setValues(values);
                    }}>
                    {renderColorTypesMenuItems("external")}                    
                    {formik.values.windowSize != WindowSize._230x230 && <MenuItem value={"ral_non_standard"}>Colore personalizzato</MenuItem>}
                </Select>
            </FormControl>
        );
    }

    const renderInputExternalFrameNsRal = () => {
        return (
            <TextField
                fullWidth 
                focused={false}
                className="mt-3"
                label={"RAL colore telaio esterno"}
                name={"coloreTelaioEsterno"}
                value={finishesProvider?.getRalName(formik.values.externalFrameColorCode)}
                InputProps={{
                    readOnly: true, 
                    startAdornment: formik.values.externalFrameColorCode && (
                        <InputAdornment position="start">
                            {finishesProvider?.getRalPreview(formik.values.externalFrameColorCode)}
                        </InputAdornment>
                    ),

                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={() => dialogs?.selectRalColor({onConfirm: selectedColor => {                                
                                let values: WindowingConfiguration = {
                                    ...formik.values,
                                    externalFrameColorCode: selectedColor
                                };

                                if (isGlasspanel() || values.windowSize == WindowSize._230x230) {
                                    values.internalFrameColorCode = selectedColor;
                                }

                                dispatcher(setFinestratura(values));
                                formik.setValues(values);
                            }})}>
                                <OpenInNewIcon style={{ color: "black" }} />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        );
    }

    const renderSelectInternalFrameColorType = () => {
        if (!doorConfigurationBuilder.configuration) return null;

        return (
            <FormControl fullWidth className="mt-3">
                <InputLabel id="colore_telaio_interno">Colore telaio interno</InputLabel>
                <Select
                    labelId="colore_telaio_interno"
                    value={getColorTypeValue("internal")}
                    label={"Colore telaio interno"}
                    onChange={(e) => {
                        let value = e.target.value;
                        let internalFrameColorType: ColorType = "ral_standard";
                        let internalFrameColorCode: NullableString = undefined;
                        if (isStandardRal(value ?? "")) {
                            internalFrameColorType = "ral_standard";
                            internalFrameColorCode = value;
                        } else {
                            internalFrameColorType = "ral_non_standard";
                            if (value != "ral_non_standard") {
                                internalFrameColorCode = value;
                            }
                        }

                        let values: WindowingConfiguration = {
                            ...formik.values,
                            internalFrameColorType,
                            internalFrameColorCode
                        };
                        dispatcher(setFinestratura(values));
                        formik.setValues(values);
                    }}>
                    {renderColorTypesMenuItems("internal")}                    
                    <MenuItem value={"ral_non_standard"}>Colore personalizzato</MenuItem>
                </Select>
            </FormControl>
        );
    }

    const renderInputInternalFrameNsRal = () => {
        return (
            <TextField
                fullWidth 
                focused={false}
                className="mt-3"
                label={"RAL colore telaio interno"}
                name={"coloreTelaioInternoNsRal"}
                value={finishesProvider?.getRalName(formik.values.internalFrameColorCode)}
                InputProps={{
                    readOnly: true, 
                    startAdornment: formik.values.internalFrameColorCode && (
                        <InputAdornment position="start">
                            {finishesProvider?.getRalPreview(formik.values.internalFrameColorCode)}
                        </InputAdornment>
                    ),

                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={() => dialogs?.selectRalColor({onConfirm: selectedColor => {                                
                                let values: WindowingConfiguration = {
                                    ...formik.values,
                                    internalFrameColorCode: selectedColor
                                };
                                dispatcher(setFinestratura(values));
                                formik.setValues(values);
                            }})}>
                                <OpenInNewIcon style={{ color: "black" }} />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        );
    }

    const isStandardRal = (value: string): boolean => {
        return value == ColorCode.bianco_traffico || 
        value == ColorCode.alluminio_brillante ||
        value == ColorCode.tinta_chiara ||
        value == ColorCode.tinta_media;
    }

    const getColorTypeValue = (colorSide: ColorSide): NullableString => {
        if (colorSide == "external") {
            if (formik.values.externalFrameColorType == "ral_standard") {
                return formik.values.externalFrameColorCode;
            }
            return formik.values.externalFrameColorType;
        } else {
            if (formik.values.internalFrameColorType == "ral_standard") {
                return formik.values.internalFrameColorCode;
            }
            return formik.values.internalFrameColorType;
        }
    }

    const renderColorTypesMenuItems = (colorSide: ColorSide) => {
        let colors: string[] = [];

        if (formik.values.windowSize == WindowSize._230x230) {
            colors.push(ColorCode.alluminio_brillante);
        } else if (isGlasspanel()) {
            if (formik.values.externalFrameColorCode && !colors.includes(formik.values.externalFrameColorCode)) {
            colors.push(formik.values.externalFrameColorCode);
            }
        } else {
            if (colorSide == "internal") {
                let internalColorCode = doorConfigurationBuilder.getInternalColorCode();
                if (internalColorCode && !colors.includes(internalColorCode) && internalColorCode != ColorCode.bianco_traffico) {
                    colors.push(internalColorCode);
                }
            }

            if (!doorConfigurationBuilder.isLaminato() && !doorConfigurationBuilder.isPietravera() && !doorConfigurationBuilder.isColoreCampione()) {
                let externalColorCode = doorConfigurationBuilder.getExternalColorCode();
                if (externalColorCode && !colors.includes(externalColorCode)) {
                    colors.push(externalColorCode);
                }
            }
            
            if (!colors.includes(ColorCode.bianco_traffico)) {
                colors.push(ColorCode.bianco_traffico);
            }
        }

        return colors.map(c => (
            <MenuItem value={c}>
                <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
                    {formik.values.windowSize != WindowSize._230x230 ? getRalName(c) : "Acciaio inox"}
                    {renderFinishPreview(c)}
                </div>
            </MenuItem>
        ));
    }

    const renderSelectGlassType = () => {
        if (!doorConfigurationBuilder.configuration) return null;

        return (
            <FormControl fullWidth className="mt-3">
                <InputLabel id="tipo_vetro">Finitura vetro</InputLabel>
                <Select
                    labelId="tipo_vetro"
                    value={formik.values.glassFinish ?? "vetro_trasparente"}
                    label={"Finitura vetro"}
                    onChange={(e) => {
                        let values: WindowingConfiguration = {
                            ...formik.values,
                            glassFinish: e.target.value as GlassType
                        };
                        dispatcher(setFinestratura(values));
                        formik.setValues(values);
                    }}>
                    {(formik.values.windowSize == WindowSize._230x230 || formik.values.windowSize == WindowSize._540x360) && <MenuItem value={GlassType.vetro_camera_4_12_4_trasparente}>Vetro camera 4-12-4 trasparente</MenuItem>}
                    {(formik.values.windowSize == WindowSize._230x230 || formik.values.windowSize == WindowSize._540x360) && <MenuItem value={GlassType.vetro_camera_4_12_4_satinato}>Vetro camera 4-12-4 satinato</MenuItem>}
                    {(formik.values.windowSize == WindowSize.glasspanel_vetro_6_7) && <MenuItem value={GlassType.vetro_6_7_sicurezza_trasparente}>TRASPARENTE</MenuItem>}
                    {(formik.values.windowSize == WindowSize.glasspanel_vetro_5) && <MenuItem value={GlassType.vetro_6_7_sicurezza_satinato_bianco}>SATINATO BIANCO</MenuItem>}
                    {(formik.values.windowSize == WindowSize.glasspanel_vetro_5) && <MenuItem value={GlassType.vetro_6_7_sicurezza_grigio_fume_trasparente}>GRIGIO FUM&Egrave; TRASPARENTE</MenuItem>}
                    {(formik.values.windowSize == WindowSize.glasspanel_vetro_5) && <MenuItem value={GlassType.vetro_6_7_sicurezza_bronzo_trasparente}>BRONZO TRASPARENTE</MenuItem>}
                    {(formik.values.windowSize == WindowSize.glasspanel_vetro_4_12_4) && <MenuItem value={GlassType.vetro_camera_temperato_4_12_4_trasparente}>TRASPARENTE</MenuItem>}
                    {(formik.values.windowSize == WindowSize.glasspanel_vetro_4_12_4) && <MenuItem value={GlassType.vetro_camera_temperato_4_12_4_satinato_bianco}>SATINATO BIANCO</MenuItem>}   
                </Select>
            </FormControl>
        );
    }

    const onPanelClicked = (panelData: PannelloRect) => {
        if (!doorConfigurationBuilder.configuration) return;
        if (!doorConfigurationBuilder.configuration.finestratura) return;
        if (!doorConfigurationBuilder.configuration.finestratura.positioning) return;
        if (!doorConfigurationBuilder.configuration.finestratura.windowSize) return;
                
        if (doorConfigurationBuilder.configuration.ventilazione) {
            let personalizzazione = doorConfigurationBuilder.configuration.ventilazione.positioning.find(f => f.panelNumber == panelData.panelNumber);
            if (personalizzazione) {
                dialogs?.showWarning("Finestratura", "Attenzione, su questo pannello hai già inserito le griglie di ventilazione");
                return;
            }
        }

        if (doorConfigurationBuilder.configuration.portinaIntegrata && panelData.panelNumber <= 4 && isGlasspanel()) {
            dialogs?.showWarning("Finestratura", "Attenzione, su questo pannello non può essere inserito il glasspanel in quanto si interseca con la portina");
            return;
        }

        let positioning = formik.values.positioning.map(p => ({...p}));
        
        if (doorConfigurationBuilder.configuration.finestratura.windowSize == "glasspanel") {            
            positioning.push({
                elementsNumber: 1,
                panelNumber: panelData.panelNumber
            });
        } else {
            let item = positioning.find(i => i.panelNumber == panelData.panelNumber);
            if (item) {
                positioning = positioning.filter(i => i != item);
            } else {                
                positioning.push({
                    elementsNumber: formik.values.quantity as number,
                    panelNumber: panelData.panelNumber
                });
            }
        }   

        let values = {...formik.values, positioning};
        formik.setValues(values);
        dispatcher(setFinestratura(values));     
    }

    const setModelloFinestra = (value: string) => {
        let windowSize: WindowSize = value as WindowSize;

        if (windowSize == WindowSize.undefined) {
            formik.setValues({
                externalFrameColorCode: undefined,
                externalFrameColorType: "ral_standard",
                internalFrameColorCode: undefined,
                internalFrameColorType: "ral_standard",
                glassFinish: undefined,
                positioning: [],
                quantity: undefined,
                windowSize
            });
            dispatcher(resetFinestratura());
            return;
        }

        let quantity: number;
        let vetro: GlassType;
        let externalFrameColorType: ColorType = "ral_standard";
        let externalFrameColorCode: NullableString = undefined;
        let internalFrameColorType: ColorType = "ral_standard";
        let internalFrameColorCode: NullableString = undefined;
        if (windowSize == WindowSize.glasspanel_vetro_6_7) {
            quantity = 1;
            vetro = GlassType.vetro_6_7_sicurezza_trasparente;
            externalFrameColorType = "ral_non_standard";
            externalFrameColorCode = doorConfigurationBuilder.getExternalColorCode();
            internalFrameColorType = "ral_non_standard";
            internalFrameColorCode = doorConfigurationBuilder.getExternalColorCode();
        } else if (windowSize == WindowSize.glasspanel_vetro_5) {
            quantity = 1;
            vetro = GlassType.vetro_6_7_sicurezza_satinato_bianco;
            externalFrameColorType = "ral_non_standard";
            externalFrameColorCode = doorConfigurationBuilder.getExternalColorCode();
            internalFrameColorType = "ral_non_standard";
            internalFrameColorCode = doorConfigurationBuilder.getExternalColorCode();
        } else if (windowSize == WindowSize.glasspanel_vetro_4_12_4) {
            quantity = 1;
            vetro = GlassType.vetro_camera_temperato_4_12_4_trasparente;
            externalFrameColorType = "ral_non_standard";
            externalFrameColorCode = doorConfigurationBuilder.getExternalColorCode();
            internalFrameColorType = "ral_non_standard";
            internalFrameColorCode = doorConfigurationBuilder.getExternalColorCode();
        } else {
            quantity = 2;
            vetro = GlassType.vetro_camera_4_12_4_trasparente;
            if (windowSize == WindowSize._540x360) {
                if (doorConfigurationBuilder.isLaminato() || doorConfigurationBuilder.isPietravera() || doorConfigurationBuilder.isColoreCampione()) {
                    externalFrameColorType = "ral_standard";
                    externalFrameColorCode = ColorCode.bianco_traffico;
                } else if (doorConfigurationBuilder.isWoodstyle()) {
                    externalFrameColorType = "ral_standard";
                    externalFrameColorCode = doorConfigurationBuilder.getExternalColorCode();
                } else {
                    externalFrameColorType = "ral_non_standard";
                    externalFrameColorCode = doorConfigurationBuilder.getExternalColorCode();
                }
                internalFrameColorType = "ral_standard";
                internalFrameColorCode = ColorCode.bianco_traffico;
            } else if (windowSize == WindowSize._230x230) {
                externalFrameColorType = "ral_standard";
                externalFrameColorCode = ColorCode.alluminio_brillante;
                internalFrameColorType = "ral_standard";
                internalFrameColorCode = ColorCode.alluminio_brillante;
            }
        }

        let values: WindowingConfiguration = {
            ...formik.values, 
            windowSize,
            quantity,
            positioning: [],
            glassFinish: vetro,
            externalFrameColorType,
            externalFrameColorCode,
            internalFrameColorType,
            internalFrameColorCode
        };
        formik.setValues(values);
        dispatcher(setFinestratura(values));
    }

    const renderDimensioneFinestra = () => {
        let porta = doorConfigurationBuilder.options.find(p => p?.optionId == doorConfigurationBuilder.configuration?.porta);
        let isPortaClassic = porta?.name == DoorType.classic;

        return (
            <FormControl fullWidth className="mt-2">
                <InputLabel id="modello_finestra">Dimensione finestra</InputLabel>
                <Select
                    labelId="modello_finestra"
                    value={formik.values.windowSize}
                    label="Dimensione finestra"
                    onChange={(e) => setModelloFinestra(e.target.value)}>
                    <MenuItem value={WindowSize.undefined}>Nessuna finestra</MenuItem>
                    <MenuItem value={WindowSize._540x360}>Finestra fissa 540x360mm con telaio in plastica RAL 9016</MenuItem>
                    <MenuItem value={WindowSize._230x230}>Finestra fissa 230X230mm con telaio in acciaio inox e vetro camera 4-12-4</MenuItem>
                    {isPortaClassic && <MenuItem value={WindowSize.glasspanel_vetro_6_7}>Sezione Glasspanel con telaio in alluminio e vetro 6/7 di sicurezza</MenuItem>}
                    {isPortaClassic && <MenuItem value={WindowSize.glasspanel_vetro_5}>Sezione Glasspanel con telaio in alluminio e vetro 5 mm</MenuItem>}
                    {isPortaClassic && <MenuItem value={WindowSize.glasspanel_vetro_4_12_4}>Sezione Glasspanel con telaio in alluminio e vetro camera temperato 4/12/4 mm</MenuItem>}
                </Select>
            </FormControl>
        );
    }

    const init = () => {
                
    }

    useEffect(() => {
        if (!id || isNaN(Number(id)) || !doorConfigurationBuilder.configuration) {
            navigate("/configurations");
        } else {
            dispatcher(setStepActive({ stepSlug: "finestratura", isActive: true }));

            init();
        }
    }, []);

    return (
        <div className="row h-full m-0">
            <div ref={sceneContainerRef} className="col-xl-7 p-0" style={{ position: "relative", height: "100%" }}>
                {doorConfigurationBuilder.configuration && (
                    <Scene
                        bbox={{
                            width: toDrawUnit(doorConfigurationBuilder.configuration.larghezzaForo),
                            height: toDrawUnit(doorConfigurationBuilder.configuration.altezzaForo),
                            x: 0,
                            y: 0
                        }}                                    
                        sidebarSx={(<SceneViewLabel text="VISTA ESTERNA" />)}
                        bottombar={(<MetricsSummary />)}
                        panEnabled
                        zoomEnabled>
                        <DoorDetails
                            options={doorConfigurationBuilder.options} 
                            onPanelClicked={onPanelClicked}>                            
                           
                        </DoorDetails>
                    </Scene>
                )}
            </div>
            <div className="col-xl-5 p-0" style={{ overflowY: "auto", height: "100%", borderLeft: "solid 1px #ccc" }}>
                <div className="p-4">
                    <h5 className="text-primary">Finestratura</h5>

                    {renderDimensioneFinestra()}
                    
                    {formik.values.windowSize != WindowSize.undefined && (
                        <>                    
                            {renderSelectQuantita()}
                            {renderSelectExternalFrameColorType()}
                            {formik.values.externalFrameColorType == "ral_non_standard" && renderInputExternalFrameNsRal()}
                            {formik.values.windowSize != WindowSize._230x230 && renderSelectInternalFrameColorType()}
                            {formik.values.internalFrameColorType == "ral_non_standard" && renderInputInternalFrameNsRal()}
                            {renderSelectGlassType()}
        
                            <p className="py-3 text-center"><ArrowBackOutlinedIcon color={"primary"} /> <strong>Clicca sul pannello per posizionare le finestre</strong></p>
                        </>
                    )}
                    
                    <div className="d-flex flex-row-reverse justify-content-between mt-3">
                        <Button disabled={isSubmit} variant="contained" className="ps-3 pe-1" onClick={() => submit()}>
                            <span>Avanti</span>
                            <NavigateNextIcon />
                        </Button>

                        <Button variant="contained" className="ps-1 pe-3" color="secondary" onClick={() => {
                            if (isStepVisible("portina-pedonale", doorConfigurationBuilder.steps)) {
                                navigate(`/door-configurations/${doorConfigurationBuilder.configuration?.configurationId}/portina-pedonale`);
                            } else {
                                navigate(`/door-configurations/${doorConfigurationBuilder.configuration?.configurationId}/aspetto`);
                            }
                        }}>
                            <NavigateBeforeIcon />
                            <span>Indietro</span>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}