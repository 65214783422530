import React, { FC } from "react";
import { Group, Line, Rect } from "react-konva";
import { SPESSORE_TELAIO_GRIGLIA } from "../../../../../../../core/common_constants";

export const Griglia: FC<{x: number, y: number, width: number, height: number, color: HTMLImageElement|null|undefined}> = (props) => {
    const {x, y, width, height} = props;

    return (
        <Group x={x} y={y}>
            <Group x={0} y={-(height/2)}>
                <Rect
                    x={0}
                    y={0} 
                    width={width} 
                    height={height} 
                    stroke={"#000"} 
                    strokeWidth={0.5}
                    fillPatternImage={props.color ? props.color : undefined}
                />
                <Rect
                    x={SPESSORE_TELAIO_GRIGLIA}
                    y={SPESSORE_TELAIO_GRIGLIA} 
                    width={width - (2*SPESSORE_TELAIO_GRIGLIA)} 
                    height={height - (2*SPESSORE_TELAIO_GRIGLIA)} 
                    stroke={"#000"} 
                    strokeWidth={0.5}
                    fillPatternImage={props.color ? props.color : undefined}
                />
                <Group x={SPESSORE_TELAIO_GRIGLIA} y={(height/2)}>
                    <Line
                        points={[0,0,width-(2*SPESSORE_TELAIO_GRIGLIA),0]}  
                        stroke={"#000"} 
                        strokeWidth={0.75}
                    />
                    <Line
                        points={[0,-(height/3)+SPESSORE_TELAIO_GRIGLIA,width-(2*SPESSORE_TELAIO_GRIGLIA),-(height/3)+SPESSORE_TELAIO_GRIGLIA]}
                        stroke={"#000"} 
                        strokeWidth={0.75}
                    />                    
                    <Line
                        points={[0,(height/3)-SPESSORE_TELAIO_GRIGLIA,width-(2*SPESSORE_TELAIO_GRIGLIA),(height/3)-SPESSORE_TELAIO_GRIGLIA]}
                        stroke={"#000"} 
                        strokeWidth={0.75}
                    />
                </Group>
            </Group>
        </Group>
    );
}