import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Scene, SceneViewLabel } from "./components/object-draw/scene";
import { Button } from "@mui/material";
import { GarageExternal } from "./components/object-draw/environment/garage_external";
import { MetricsSummary } from "./components/panels/pnl_metrics_summary";
import { useStoreDispatch, useStoreSelector } from "../../../../../hooks/StoreHooks";
import { getCurrentUser, isStepVisible, toDrawUnit } from "../../../../core/common_functions";
import { useDoorConfigurationBuilderActions } from "../../../../../store/reducers/door_configuration_builder";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { useDialogs } from "../../../../providers/dialogs_provider";
import { DoorType, FinishType } from "../../../../core/common_types";
import { useConfigurationsService } from "../../../../services/configurations_service";
import { ExternalDoorOptions } from "../../../../models/door_configuration";
import { PROFONDITA_INVOLUCRO } from "../../../../core/common_constants";
import Loading from "../../../../../components/Loading/loading";
import { FormPortinaAffiancata, FormPortinaAffiancataTypeRef } from "./components/forms/frm_portina_affiancata";
import { PortinaAffiancata } from "./components/object-draw/accessories/portina_affiancata";
import { ExternalDoor } from "./components/object-draw/environment/external_door";

export const ExternalDoorPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { base, doorConfigurationBuilder } = useStoreSelector(store => store);
  const dispatcher = useStoreDispatch();
  const currentUser = getCurrentUser(base);
  const { setStepActive, setStepComplete, setStepVisible } = useDoorConfigurationBuilderActions();
  const dialogs = useDialogs();
  const [isSubmit, setSubmit] = useState<boolean>(false);
  const [options, setOptions] = useState<{items: ExternalDoorOptions|null, isLoading: boolean}>({
    items: null,
    isLoading: true
  });
  const configurationService = useConfigurationsService();
  const sceneContainerRef = useRef<HTMLDivElement>(null);
  const formPortinaAffiancataRef = useRef<FormPortinaAffiancataTypeRef>(null);
  
  const submit = async () => {
    setSubmit(true);
    try {
      if (!doorConfigurationBuilder.configuration) throw "Invalid configuration";

      let data = await formPortinaAffiancataRef.current?.getData();
      if (!data) throw "Invalid configuration";

      await configurationService.setPortinaAffiancata(currentUser, doorConfigurationBuilder.configuration.configurationId, data);

      setSubmit(false);
      
      dispatcher(setStepComplete({ stepSlug: "portina-pedonale-affiancata", isComplete: true }));

      if (doorConfigurationBuilder.configuration.isMotorizzata) {
        navigate(`/door-configurations/${id}/motorizzazione`);
      } else {
        navigate(`/door-configurations/${id}/accessori-vari`);
      }
    } catch (e: any) {
      setSubmit(false);

      if (typeof e == "string") {
        dialogs?.showError("Attenzione...", e);
      } else {
        if (e.severity == "error") {
          dialogs?.showError("Attenzione...", e.message);
        } else if (e.severity == "warning") {
          dialogs?.showWarning("Attenzione...", e.message);
        }
      }
    }
  }

  const loadPortinaAffiancata = async () => {
    try {
      if (!doorConfigurationBuilder.configuration) throw "Configuration error";

      let items = await configurationService.findCompatibleExternalDoorAccessories(currentUser, doorConfigurationBuilder.configuration.configurationId);

      setOptions({...options, items, isLoading: false});
    } catch (e: any) {
      setOptions({...options, isLoading: false});

      if (typeof e == "string") {
        dialogs?.showError("Attenzione...", e);
      } else {
        if (e.severity == "error") {
          dialogs?.showError("Attenzione...", e.message);
        } else if (e.severity == "warning") {
          dialogs?.showWarning("Attenzione...", e.message);
        }
      }
    }
  }

  useEffect(() => {
    if (!id || isNaN(Number(id)) || !doorConfigurationBuilder.configuration) {
      navigate("/configurations");
    } else {
      loadPortinaAffiancata();

      dispatcher(setStepActive({ stepSlug: "portina-pedonale-affiancata", isActive: true }));
    }
  }, []);

  return (
    <div className="row h-full m-0">
      <div ref={sceneContainerRef} className="col-xl-7 p-0" style={{ position: "relative", height: "100%" }}>
        {doorConfigurationBuilder.configuration && doorConfigurationBuilder.configuration.portinaAffiancata && (
          <Scene
            bbox={{
              x: 0,
              y: 0,
              width: toDrawUnit(doorConfigurationBuilder.configuration.larghezzaForo),
              height: toDrawUnit(doorConfigurationBuilder.configuration.altezzaForo)
            }}
            sidebarSx={(<SceneViewLabel text="VISTA ESTERNA" />)}
            panEnabled
            zoomEnabled>
            {doorConfigurationBuilder.configuration.portinaAffiancata && doorConfigurationBuilder.configuration.portinaAffiancata.portina && <ExternalDoor options={doorConfigurationBuilder.options}>
            </ExternalDoor>}
          </Scene>
        )}
      </div>
      <div className="col-xl-5 p-0" style={{ overflowY: "auto", height: "100%", borderLeft: "solid 1px #ccc" }}>
        <div className="p-4">
          <h5 className="text-primary">Portina pedonale affiancata</h5>

          {options.isLoading && <Loading />}

          {!options.isLoading && options.items && <FormPortinaAffiancata ref={formPortinaAffiancataRef} options={options.items} />}

          <div className="d-flex flex-row-reverse justify-content-between mt-3">
            <Button disabled={isSubmit} variant="contained" className="ps-3 pe-1" onClick={() => submit()}>
              <span>Avanti</span>
              <NavigateNextIcon />
            </Button>

            <Button variant="contained" className="ps-1 pe-3" color="secondary" onClick={() => {
              if (isStepVisible("ventilazione", doorConfigurationBuilder.steps)) {
                navigate(`/door-configurations/${doorConfigurationBuilder.configuration?.configurationId}/ventilazione`);  
              } else if (doorConfigurationBuilder.configuration && doorConfigurationBuilder.configuration.portinaPedonaleIntegrata) {
                navigate(`/door-configurations/${doorConfigurationBuilder.configuration?.configurationId}/portina-pedonale`);
              } else {
                navigate(`/door-configurations/${doorConfigurationBuilder.configuration?.configurationId}/aspetto`);
              }
            }}>
              <NavigateBeforeIcon />
              <span>Indietro</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}