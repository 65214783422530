import Konva from "konva";
import { Vector2d } from "konva/lib/types";
import React, { FC, useEffect, useRef } from "react";
import { Group, Label, Layer, Line, Tag, Text } from "react-konva";
import { Vector } from "vecti";
import { PROFONDITA_INVOLUCRO } from "../../../../../../../core/common_constants";

export const PortinaAffiancata: React.FC<{ pf: Vector, hp: number, lp: number }> = (props) => {
    const { hp, lp, pf } = props; 
    
    const labelRef = useRef<Konva.Label>(null);

    const setLabelPosition = () => {
        if (!labelRef.current) return;
        
        let lw = labelRef.current.getWidth();
        let lh = labelRef.current.getHeight();
        
        let y = (hp/2)-(lh/2);
        let x = (lp/2)-(lw/2);
        let position: Vector2d = {x, y};
        labelRef.current.setPosition(position);        
    }

    useEffect(() => {
        //setTimeout(() => {
            setLabelPosition();
        //}, 100);
    }, []);

    return (
        <Group>
            {/* <Parete l={lp+PROFONDITA_INVOLUCRO} h={hp+(PROFONDITA_INVOLUCRO/2)} /> */}
            <Label ref={labelRef}>
                <Tag fill="white" stroke="black" strokeWidth={0.5} />
                <Text 
                    text={"ANTEPRIMA IN ALLESTIMENTO"} 
                    align="center"
                    lineHeight={1.5}
                    padding={8} 
                    fontSize={14}
                    fontStyle="bold"                                        
                />
            </Label>
        </Group>
    );
}

const Parete: FC<{h: number, l: number}> = (props) => {
    const {h, l} = props;

    return (
        <Line
            points={[-PROFONDITA_INVOLUCRO,-PROFONDITA_INVOLUCRO,h,h,-PROFONDITA_INVOLUCRO,h,-PROFONDITA_INVOLUCRO,-PROFONDITA_INVOLUCRO,l+(2 * PROFONDITA_INVOLUCRO)]}
            strokeWidth={1}
            stroke={"#000"}
            closed
        />
    );
}